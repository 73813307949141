import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Text, Checkbox } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Input from '../../components/form/Input';

import GlobalContext from '../../contexts/GlobalContext';
import validateUtil from '../../utils/validate';
import updateForm from '../../utils/updateForm';

import useApiInstructor from '../../api/useApiInstructor';

const INITIAL_FORM = {
  name: '',
  external: false,
  active: true,
  branch: 1,
};

const InstructorForm = () => {
  const { loading, toast } = useContext(GlobalContext);
  const { id } = useParams();
  const { apiInstructorCreate, apiInstructorGet, apiInstructorUpdate } =
    useApiInstructor();
  const navigate = useNavigate();

  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});
  const [addOrEdit, setAddOrEdit] = useState('Agregar');

  const getData = useCallback(async () => {
    try {
      const resp = await apiInstructorGet(id);
      setForm(resp.data.data.attributes);
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (id) {
      setAddOrEdit('Editar');
      getData();
    }
  }, [id, getData]);

  const validate = () => {
    const validations = [
      {
        name: 'name',
        value: form.name,
        validations: { required: true },
      },
    ];
    const validateResult = validateUtil(validations);
    if (!validateResult.valid) {
      setErrors(validateResult.errors);
    }
    return validateResult.valid;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      if (id) {
        await apiInstructorUpdate(id, form);
      } else {
        await apiInstructorCreate(form);
      }
      navigate('/instructores');
      toast.fire({
        icon: 'success',
        title: `Instructor ${form.name} ${
          id ? 'actualizado' : 'creado'
        } exitosamente.`,
      });
    } catch (error) {
      console.log('error: ', error);
      setErrors({
        general: `Ocurrio un error al ${
          id ? 'actualizar' : 'crear'
        } el instructor.`,
      });
    }
  };

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setErrors({});
  };

  return (
    <Layout
      menu="instructors"
      title={`${addOrEdit} Instructor`}
      backUrl="/instructores"
    >
      <Card className="mt-4 form-view">
        <form onSubmit={submit}>
          <Input
            label="Nombre"
            id="name"
            name="name"
            value={form.name}
            required
            onChange={handleChange}
            helperText={errors.name}
          />
          <Checkbox
            id="external"
            name="external"
            checked={form.external}
            onChange={handleChange}
            className="mb-4"
          >
            Externo
          </Checkbox>
          {id && (
            <Checkbox
              id="active"
              name="active"
              checked={form.active}
              onChange={handleChange}
              className="mb-4 ml-5"
            >
              Activo
            </Checkbox>
          )}
          {errors.general && (
            <div className="mb-2">
              <Text color="error" className="mb-4">
                {errors.general}
              </Text>
            </div>
          )}
          <Button type="submit" clickable={!loading}>
            {`${addOrEdit === 'Agregar' ? addOrEdit : 'Actualizar'} instructor`}
          </Button>
        </form>
      </Card>
    </Layout>
  );
};

export default InstructorForm;
