import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';
import { sleep } from '../utils/utils';

const useApiAuth = () => {
  const { setLoading, setToken } = useContext(GlobalContext);

  let API = Constructor(); // Create instance

  /**
   * Endpoint to login
   * @param {string} identifier System user or e-mail
   * @param {string} password User password
   * @returns Promise
   */
  const apiLogin = async (identifier, password) => {
    setLoading(true);
    try {
      const DATA = { identifier, password };
      const resp = await API.post('/auth/local', DATA);
      setToken(resp.data.jwt);
      await sleep(100);
      API = Constructor();
      return await API.get('/users/me?populate=*');
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiLogin,
  };
};

export default useApiAuth;
