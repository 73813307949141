/**
 * Function to update the state object
 * @param {string} name Name of state to update
 * @param {*} value Value of state to update
 * @param {object} dataObject Object of state
 * @returns Updated object
 */
const updateForm = (e, dataObject) => {
  const { target, nativeEvent } = e;
  let {
    target: { name, value },
  } = e;
  if (nativeEvent && nativeEvent.target.type === 'checkbox') {
    value = target.checked;
    name = nativeEvent.target.name;
  }
  const data = {
    ...dataObject,
    [name]: value,
  };
  return data;
};

export default updateForm;
