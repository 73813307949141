import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import GlobalContext from '../contexts/GlobalContext';

// A wrapper for <Route> that redirects to the dashboard
// screen if you're authenticated.
const PublicRoute = ({ component: Component }) => {
  const { globalState } = useContext(GlobalContext);

  if (globalState.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Component />;
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PublicRoute;
