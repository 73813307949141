import React from 'react';
import { Grid } from '@nextui-org/react';

import Layout from '../../../components/layout/Layout';
import Form from './Form';
import Table from './Table';

import { EmployeeImportProvider } from '../../../contexts/EmployeeImportContext';

const EmployeeImport = () => (
  <Layout menu="employees" title="Importar empleados">
    <EmployeeImportProvider>
      <Form />
      <Grid.Container gap={1} justify="left" className="mt-4">
        <Table title="Empleados agregados" />
        <Table title="Empleados no agregados" type="notAdded" />
        <Table title="Empleados ignorados" type="ignored" />
      </Grid.Container>
    </EmployeeImportProvider>
  </Layout>
);

export default EmployeeImport;
