import React, { useContext, useState } from 'react';
import { Button, Loading, Text } from '@nextui-org/react';

import Input from '../components/form/Input';
import InputPassword from '../components/form/InputPassword';

import GlobalContext from '../contexts/GlobalContext';
import validateUtil from '../utils/validate';
import updateForm from '../utils/updateForm';

import useApiAuth from '../api/useApiAuth';

import logo from '../assets/img/logo.png';

const INITIAL_FORM = {
  identifier: '',
  password: '',
};

const Login = () => {
  const { signUp, loading } = useContext(GlobalContext);
  const { apiLogin } = useApiAuth();

  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});

  /**
   * Validate required fields and email format
   * If there are errors update the error status so that they are shown in each input
   * @returns bool with validation status
   */
  const validate = () => {
    const { identifier, password } = form;
    const validations = [
      { name: 'identifier', value: identifier, validations: { required: true } },
      { name: 'password', value: password, validations: { required: true } },
    ];
    const validateResult = validateUtil(validations);
    if (!validateResult.valid) {
      setErrors(validateResult.errors);
    }
    return validateResult.valid;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const { identifier, password } = form;
    try {
      const resp = await apiLogin(identifier, password);
      signUp(resp.data);
    } catch (error) {
      setErrors({ general: 'Usuario o contraseña incorrecta.' });
    }
  };

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setErrors({});
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={submit}>
        <img src={logo} alt="tracos" className="logo" />
        <Text h2 margin="1rem">Iniciar sesión</Text>
        <Input
          label="Usuario ó correo electrónico"
          id="identifier"
          name="identifier"
          value={form.identifier}
          required
          onChange={handleChange}
          helperText={errors.identifier}
        />
        <InputPassword
          label="Contraseña"
          id="password"
          name="password"
          value={form.password}
          required
          onChange={handleChange}
          helperText={errors.password}
          classNameWrapper="mb-2"
        />
        {errors.general && (
          <div className="mb-2">
            <Text color="error" className="mb-4">
              {errors.general}
            </Text>
          </div>
        )}
        <Button type="submit" clickable={!loading}>
          {loading
            ? <Loading type="points" color="white" size="sm" />
            : 'Ingresar'}
        </Button>
      </form>
      <div className="bg-container" />
    </div>
  );
};

export default Login;
