import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const INITIAL_EMPLOYEES = {
  added: [],
  notAdded: [],
  ignored: [],
};

// Creation of the context
const EmployeeImportContext = createContext();

/**
 * Allows consuming components to subscribe to context changes
 * @param {component} children
 */
const EmployeeImportProvider = ({ children }) => {
  const [employees, setEmployees] = useState(INITIAL_EMPLOYEES);
  const [processed, setProcessed] = useState(false);

  const values = useMemo(
    () => ({
      employees,
      setEmployees,
      processed,
      setProcessed,
    }),
    [employees, processed],
  );

  return (
    <EmployeeImportContext.Provider value={values}>
      {children}
    </EmployeeImportContext.Provider>
  );
};

EmployeeImportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { EmployeeImportProvider };
export default EmployeeImportContext;
