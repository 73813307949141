import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

import GlobalContext from './GlobalContext';

import useApiCourse from '../api/useApiCourse';
import useApiReceiver from '../api/useApiReceiver';
import useApiTraining from '../api/useApiTraining';
import useApiInstructor from '../api/useApiInstructor';
import useApiEvaluationForm from '../api/useApiEvaluationForm';
import useApiEmployee from '../api/useApiEmployee';

import validateUtil from '../utils/validate';
import updateForm from '../utils/updateForm';

export const EMPTY_CBO_OBJ = { value: '', label: '' };

const INITIAL_FORM = {
  reason: '',
  date: '',
  receiver: EMPTY_CBO_OBJ,
  totalDuration: '0',
};

const TOPICS_FORM = {
  training: EMPTY_CBO_OBJ,
  instructor: EMPTY_CBO_OBJ,
  evaluationForm: EMPTY_CBO_OBJ,
  observation: '',
  duration: '',
};

// Creation of the context
const CourseContext = createContext();

/**
 * Allows consuming components to subscribe to context changes
 * @param {component} children
 */
const CourseProvider = ({ children }) => {
  const { globalState, toast } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    apiCourseCreate,
    apiCourseDetailCreate,
    apiCoursexGet,
    apiCourseUpdate,
  } = useApiCourse();
  const { apiReceiverCombo } = useApiReceiver();
  const { apiTrainingCombo } = useApiTraining();
  const { apiInstructorCombo } = useApiInstructor();
  const { apiEvaluationFormCombo } = useApiEvaluationForm();
  const { apiEmployeeComboComplete } = useApiEmployee();

  const [form, setForm] = useState(INITIAL_FORM);
  const [topic, setTopic] = useState(TOPICS_FORM);
  const [errors, setErrors] = useState({});
  const [addOrEdit, setAddOrEdit] = useState('Agregar');
  const [receivers, setReceivers] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [evaluationForms, setEvaluationForms] = useState([]);
  const [addedTopics, setAddedTopics] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesAdded, setEmployeesAdded] = useState([]);

  const getData = useCallback(async (allEmployees, allTrainings) => {
    try {
      const resp = await apiCoursexGet(id);
      const { data } = resp;

      const frm = {
        reason: data.reason,
        date: data.date,
        receiver: { value: data.receiver.id, label: data.receiver.name },
        totalDuration: data.duration,
      };
      setForm(frm);

      const tpics = [];
      // let traings = [...allTrainings];
      for (const x of data.detail) {
        tpics.push({
          duration: x.duration,
          evaluationForm: {
            value: x.evaluation_form.id,
            label: x.evaluation_form.name,
          },
          instructor: { value: x.instructor.id, label: x.instructor.name },
          observation: x.observation,
          training: {
            value: x.training.id,
            label: `${x.training.code} - ${x.training.name}`,
            duration: x.training.duration,
          },
          id: x.id,
        });
        // traings = traings.filter((z) => z.value !== x.training.id.toString());
      }
      setAddedTopics(tpics);
      setTrainings(allTrainings);

      let emplyees = [...allEmployees];
      const emplyeesAdd = [];
      for (const x of data.employees) {
        const empl = allEmployees.find((y) => y.id === x.id);
        emplyeesAdd.push(empl);
        emplyees = emplyees.filter((z) => z.id !== x.id);
      }
      setEmployeesAdded(emplyeesAdd);
      setEmployees(emplyees);
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  const getComplementaryData = useCallback(async () => {
    const promises = [
      apiReceiverCombo(),
      apiTrainingCombo(),
      apiInstructorCombo(),
      apiEvaluationFormCombo(),
      apiEmployeeComboComplete(),
    ];

    try {
      const [
        respReceiver,
        respTraining,
        respInstructor,
        respEvaluationForm,
        respEmployees,
      ] = await Promise.allSettled(promises);

      if (respReceiver.status === 'fulfilled') {
        const cboReceiver = respReceiver.value.data.data.reduce(
          (accumulator, x) => {
            accumulator.push({
              value: x.id.toString(),
              label: x.attributes.name,
            });
            return accumulator;
          },
          [],
        );
        setReceivers(cboReceiver);
      }

      let tempTrainings = [];
      if (respTraining.status === 'fulfilled') {
        const cboTraining = respTraining.value.data.data.reduce(
          (accumulator, x) => {
            accumulator.push({
              value: x.id.toString(),
              label: `${x.attributes.code} - ${x.attributes.name}`,
              duration: x.attributes.duration,
            });
            return accumulator;
          },
          [],
        );
        setTrainings(cboTraining);
        if (id) tempTrainings = [...cboTraining];
      }

      if (respInstructor.status === 'fulfilled') {
        const cboInstructor = respInstructor.value.data.data.reduce(
          (accumulator, x) => {
            accumulator.push({
              value: x.id.toString(),
              label: `${x.attributes.name} - ${
                x.attributes.external ? 'externo' : 'interno'
              }`,
            });
            return accumulator;
          },
          [],
        );
        setInstructors(cboInstructor);
      }

      if (respEvaluationForm.status === 'fulfilled') {
        const cboEvaluationForm = respEvaluationForm.value.data.data.reduce(
          (accumulator, x) => {
            accumulator.push({
              value: x.id.toString(),
              label: x.attributes.name,
            });
            return accumulator;
          },
          [],
        );
        setEvaluationForms(cboEvaluationForm);
      }

      let tempEmployees = [];
      if (respEmployees.status === 'fulfilled') {
        const cboEmployees = respEmployees.value.data.reduce(
          (accumulator, x) => {
            accumulator.push({
              value: x.id.toString(),
              label: `${x.number} - ${x.name} ${x.lastName}`,
              id: x.id,
              number: x.number,
              name: x.name,
              lastName: x.lastName,
              department: x.department?.name || '',
              jobPosition: x.jobPosition.name,
              turn: x.turn.name,
            });
            return accumulator;
          },
          [],
        );
        setEmployees(cboEmployees);
        if (id) tempEmployees = [...cboEmployees];
      }

      if (id) {
        setAddOrEdit('Editar');
        await getData(tempEmployees, tempTrainings);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getComplementaryData();
  }, [getComplementaryData]);

  useEffect(() => {
    let total = 0;
    for (const x of addedTopics) {
      total += parseInt(x.duration, 10);
    }
    const e = { target: { name: 'totalDuration', value: total } };
    setForm(updateForm(e, form));
    setErrors({});
  }, [addedTopics]); // eslint-disable-line

  const validate = useCallback(() => {
    const validations = [
      {
        name: 'reason',
        value: form.reason,
        validations: { required: true },
      },
      {
        name: 'date',
        value: form.date,
        validations: { required: true },
      },
      {
        name: 'receiver',
        value: form.receiver,
        validations: { required: true },
      },
    ];
    const validateResult = validateUtil(validations);
    if (!validateResult.valid) {
      setErrors(validateResult.errors);
    }
    return validateResult.valid;
  }, [form]);

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!validate()) {
        return;
      }
      if (addedTopics.length === 0) {
        toast.fire({
          icon: 'warning',
          title: 'Aun no haz agregado temas a cursar.',
        });
        return;
      }
      if (employeesAdded.length === 0) {
        toast.fire({
          icon: 'warning',
          title: 'Aun no haz agregado empleados en el curso.',
        });
        return;
      }

      let courseId = 0;

      // Create course
      try {
        const idsEmployees = [];
        for (const x of employeesAdded) {
          idsEmployees.push(x.id);
        }
        const courseData = {
          date: form.date,
          duration: form.totalDuration,
          reason: form.reason,
          branch: 1,
          receiver: parseInt(form.receiver.value, 10),
          employees: idsEmployees,
        };
        if (id) {
          await apiCourseUpdate(id, courseData);
          courseId = id;
        } else {
          const resp = await apiCourseCreate(courseData);
          courseId = resp.data.data.id;
        }
      } catch (error) {
        console.log('error: ', error);
        setErrors({
          general: `Ocurrio un error al ${
            id ? 'actualizar' : 'crear'
          } el curso.`,
        });
      }

      // Create course-detail
      if (courseId) {
        try {
          const promisesDetails = [];
          for (const x of addedTopics) {
            if (!x.id) {
              promisesDetails.push(
                apiCourseDetailCreate({
                  observation: x.observation,
                  duration: x.duration,
                  course: courseId,
                  training: parseInt(x.training.value, 10),
                  instructor: parseInt(x.instructor.value, 10),
                  evaluation_form: parseInt(x.evaluationForm.value, 10),
                }),
              );
            }
          }
          await Promise.allSettled(promisesDetails);
        } catch (error) {
          console.log('error: ', error);
          setErrors({
            general: `Ocurrio un error al ${
              id ? 'actualizar' : 'crear'
            } el detalle del curso.`,
          });
        }
      } else {
        toast.fire({
          icon: 'error',
          title: `Ocurrio un error al ${id ? 'actualizar' : 'crear'} el curso.`,
        });
        return;
      }
      navigate('/cursos');
      toast.fire({
        icon: 'success',
        title: `Curso ${id ? 'actualizado' : 'creado'} exitosamente.`,
      });
    },
    [
      apiCourseCreate,
      apiCourseDetailCreate,
      apiCourseUpdate,
      form,
      id,
      navigate,
      toast,
      validate,
      employeesAdded,
      addedTopics,
    ],
  );

  const resetTopic = () => {
    setTopic(TOPICS_FORM);
  };

  const values = useMemo(
    () => ({
      form,
      setForm,
      topic,
      setTopic,
      errors,
      setErrors,
      receivers,
      setReceivers,
      trainings,
      setTrainings,
      instructors,
      evaluationForms,
      addedTopics,
      setAddedTopics,
      employees,
      setEmployees,
      employeesAdded,
      setEmployeesAdded,
      resetTopic,
      companyId: globalState.user.company.id,
      addOrEdit,
      submit,
    }),
    [
      form,
      topic,
      errors,
      receivers,
      trainings,
      instructors,
      evaluationForms,
      globalState,
      addedTopics,
      employees,
      employeesAdded,
      addOrEdit,
      submit,
    ],
  );

  return (
    <CourseContext.Provider value={values}>{children}</CourseContext.Provider>
  );
};

CourseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CourseProvider };
export default CourseContext;
