import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiEvaluationForm = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiEvaluationFormList = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/evaluation-forms?sort[0]=name:asc&filters[active][$eq]=true',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEvaluationFormCombo = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/evaluation-forms?sort[0]=name:asc&filters[active][$eq]=true&fields[0]=name',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEvaluationFormGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(`/evaluation-forms/${id}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEvaluationFormCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/evaluation-forms', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEvaluationFormUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/evaluation-forms/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiEvaluationFormList,
    apiEvaluationFormCombo,
    apiEvaluationFormGet,
    apiEvaluationFormCreate,
    apiEvaluationFormUpdate,
  };
};

export default useApiEvaluationForm;
