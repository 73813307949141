import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Text, Checkbox } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';

import GlobalContext from '../../contexts/GlobalContext';
import validateUtil from '../../utils/validate';
import updateForm from '../../utils/updateForm';

import useApiEmployee from '../../api/useApiEmployee';
import useApiDepartment from '../../api/useApiDepartment';
import useApiJobPosition from '../../api/useApiJobPosition';
import useApiTurn from '../../api/useApiTurn';

const INITIAL_FORM = {
  number: '',
  name: '',
  lastName: '',
  dateAdmission: '',
  active: true,
  branch: 1,
  department: '',
  jobPosition: '',
  turn: '',
};

const EmployeeForm = () => {
  const { loading, toast } = useContext(GlobalContext);
  const { id } = useParams();
  const { apiEmployeeCreate, apiEmployeeGet, apiEmployeeUpdate } =
    useApiEmployee();
  const { apiDepartmentCombo } = useApiDepartment();
  const { apiJobPositionCombo } = useApiJobPosition();
  const { apiTurnCombo } = useApiTurn();
  const navigate = useNavigate();

  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});
  const [addOrEdit, setAddOrEdit] = useState('Agregar');
  const [departments, setDepartments] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const [turns, setTurns] = useState([]);

  const getData = useCallback(async () => {
    try {
      const resp = await apiEmployeeGet(id);
      const {
        data: {
          data: { attributes: data },
        },
      } = resp;
      setForm({
        number: data.number,
        name: data.name,
        lastName: data.lastName,
        dateAdmission: data.dateAdmission,
        jobPosition: data.jobPosition.data?.id,
        department: data.department.data?.id,
        turn: data.turn.data?.id,
        active: data.active,
        branch: 1,
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  const getComplementaryData = useCallback(async () => {
    try {
      const resp = await apiDepartmentCombo();
      setDepartments(resp.data.data);
    } catch (error) {
      console.log('error: ', error);
    }
    try {
      const resp = await apiJobPositionCombo();
      setJobPositions(resp.data.data);
    } catch (error) {
      console.log('error: ', error);
    }
    try {
      const resp = await apiTurnCombo();
      setTurns(resp.data.data);
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (id) {
      setAddOrEdit('Editar');
      getData();
    }
    getComplementaryData();
  }, [id, getData, getComplementaryData]);

  const validate = () => {
    const validations = [
      {
        name: 'number',
        value: form.number,
        validations: { required: true },
      },
      {
        name: 'name',
        value: form.name,
        validations: { required: true },
      },
      {
        name: 'lastName',
        value: form.lastName,
        validations: { required: true },
      },
      {
        name: 'dateAdmission',
        value: form.dateAdmission,
        validations: { required: true },
      },
      {
        name: 'department',
        value: form.department,
        validations: { required: true },
      },
      {
        name: 'jobPosition',
        value: form.jobPosition,
        validations: { required: true },
      },
      {
        name: 'turn',
        value: form.turn,
        validations: { required: true },
      },
    ];
    const validateResult = validateUtil(validations);
    if (!validateResult.valid) {
      setErrors(validateResult.errors);
    }
    return validateResult.valid;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      if (id) {
        await apiEmployeeUpdate(id, form);
      } else {
        await apiEmployeeCreate(form);
      }
      navigate('/empleados');
      toast.fire({
        icon: 'success',
        title: `Empleado ${form.name} ${
          id ? 'actualizado' : 'creado'
        } exitosamente.`,
      });
    } catch (error) {
      console.log('error: ', error);
      setErrors({
        general: `Ocurrio un error al ${
          id ? 'actualizar' : 'crear'
        } el empleado.`,
      });
    }
  };

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setErrors({});
  };

  return (
    <Layout
      menu="employees"
      title={`${addOrEdit} Empleado`}
      backUrl="/empleados"
    >
      <Card className="mt-4 form-view">
        <form onSubmit={submit}>
          <Input
            label="Número"
            id="number"
            name="number"
            value={form.number}
            required
            onChange={handleChange}
            helperText={errors.number}
          />
          <Input
            label="Nombre"
            id="name"
            name="name"
            value={form.name}
            required
            onChange={handleChange}
            helperText={errors.name}
          />
          <Input
            label="Apellido"
            id="lastName"
            name="lastName"
            value={form.lastName}
            required
            onChange={handleChange}
            helperText={errors.lastName}
          />
          <Input
            label="Fecha de contratación"
            id="dateAdmission"
            name="dateAdmission"
            value={form.dateAdmission}
            required
            onChange={handleChange}
            helperText={errors.dateAdmission}
          />
          <Select
            label="Puesto"
            id="jobPosition"
            name="jobPosition"
            value={form.jobPosition}
            required
            onChange={handleChange}
            helperText={errors.jobPosition}
          >
            <option value="">--Selecciona--</option>
            {jobPositions.map((x) => (
              <option value={x.id} key={x.id}>
                {x.attributes.name}
              </option>
            ))}
          </Select>
          <Select
            label="Departamento"
            id="department"
            name="department"
            value={form.department}
            required
            onChange={handleChange}
            helperText={errors.department}
          >
            <option value="">--Selecciona--</option>
            {departments.map((d) => (
              <option value={d.id} key={d.id}>
                {d.attributes.name}
              </option>
            ))}
          </Select>
          <Select
            label="Turno"
            id="turn"
            name="turn"
            value={form.turn}
            required
            onChange={handleChange}
            helperText={errors.turn}
          >
            <option value="">--Selecciona--</option>
            {turns.map((x) => (
              <option value={x.id} key={x.id}>
                {x.attributes.name}
              </option>
            ))}
          </Select>
          {id && (
            <Checkbox
              id="active"
              name="active"
              checked={form.active}
              onChange={handleChange}
              className="mb-4"
            >
              Activo
            </Checkbox>
          )}
          {errors.general && (
            <div className="mb-2">
              <Text color="error" className="mb-4">
                {errors.general}
              </Text>
            </div>
          )}
          <Button type="submit" clickable={!loading}>
            {`${addOrEdit === 'Agregar' ? addOrEdit : 'Actualizar'} empleado`}
          </Button>
        </form>
      </Card>
    </Layout>
  );
};

export default EmployeeForm;
