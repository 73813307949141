import React, { useContext } from 'react';
import { MenuAlt2Icon } from '@heroicons/react/outline';

import GlobalContext from '../../contexts/GlobalContext';

const Header = () => {
  const { toggleMenu } = useContext(GlobalContext);

  return (
    <header className="header">
      <div className="wrapper-menu-icon" onClick={toggleMenu} role="button" tabIndex={0}>
        <MenuAlt2Icon className="menu-icon" />
      </div>
    </header>
  );
};

export default Header;
