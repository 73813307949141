import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiReports = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiCourseXEmployee = async (data) => {
    setLoading(true);
    try {
      return await API.post('/report/course-x-employee', data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiGeneralTraining = async (data) => {
    setLoading(true);
    try {
      return await API.post('/report/general-training', data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTrainingHoursReceived = async (data) => {
    setLoading(true);
    try {
      return await API.post('/report/training-hours-received', data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiCourseXEmployee,
    apiGeneralTraining,
    apiTrainingHoursReceived,
  };
};

export default useApiReports;
