import React, { useContext } from 'react';

import GlobalContext from '../../../contexts/GlobalContext';

import Footer from './Footer';
import Header from './Header';
import Menu from './Menu';

const SideBar = () => {
  const { menuOpen } = useContext(GlobalContext);

  return (
    <aside className={`sidebar${menuOpen ? ' open' : ''}`}>
      <Header />
      <Menu />
      <Footer />
    </aside>
  );
};

export default SideBar;
