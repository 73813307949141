import React, { useContext } from 'react';
import { Button, Grid } from '@nextui-org/react';
import { TrashIcon } from '@heroicons/react/outline';

import CourseContext from '../../../contexts/CouseContext';

const Table = () => {
  const { addedTopics, setAddedTopics, trainings, setTrainings } =
    useContext(CourseContext);

  const removeTopic = async (topic) => {
    const newAdded = addedTopics.filter((x) => x !== topic);
    setAddedTopics(newAdded);
    const newTrainings = [...trainings, topic.training];
    setTrainings(newTrainings);
  };

  return (
    <Grid xs={12} className="mt-4">
      <div className="table-responsive w-100">
        <table className="table table-striped ">
          <thead>
            <tr>
              <th>Entrenamiento</th>
              <th>Instructor</th>
              <th>Forma de evaluación</th>
              <th>Duración</th>
              <th>Observaciones</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {addedTopics.length > 0 ? (
              <>
                {addedTopics.map((x) => (
                  <tr key={x.training.value}>
                    <td>{x.training.label}</td>
                    <td>{x.instructor.label}</td>
                    <td>{x.evaluationForm.label}</td>
                    <td>{x.duration}</td>
                    <td>{x.observation}</td>
                    <td>
                      {x.id ? null : (
                        <Button
                          type="button"
                          light
                          color="error"
                          auto
                          onClick={() => removeTopic(x)}
                        >
                          <TrashIcon width="1rem" />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No existen registros
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Grid>
  );
};

export default Table;
