import React from 'react';
import { Textarea as TextareaNextUI } from '@nextui-org/react';
import PropTypes from 'prop-types';

const Textarea = ({ classNameWrapper, helperText, ...props }) => (
  <div
    className={`form-control${
      classNameWrapper ? ` ${classNameWrapper}` : ''
    }`}
  >
    <TextareaNextUI
      bordered
      fullWidth
      color={helperText ? 'error' : 'primary'}
      helperText={helperText}
      helperColor="error"
      {...props}
    />
  </div>
);

Textarea.propTypes = {
  classNameWrapper: PropTypes.string,
  helperText: PropTypes.string,
};

Textarea.defaultProps = {
  classNameWrapper: '',
  helperText: '',
};

export default Textarea;
