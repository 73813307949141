import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiTraining = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiTrainingList = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/trainings?sort[0]=name:asc&filters[active][$eq]=true',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTrainingCombo = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/trainings?sort[0]=name:asc&filters[active][$eq]=true&fields[0]=name&fields[1]=code&fields[2]=duration',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTrainingGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(
        `/trainings/${id}?populate[company][fields][0]=name`,
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTrainingCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/trainings', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTrainingUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/trainings/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiTrainingList,
    apiTrainingCombo,
    apiTrainingGet,
    apiTrainingCreate,
    apiTrainingUpdate,
  };
};

export default useApiTraining;
