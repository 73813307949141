import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Text, Checkbox } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Input from '../../components/form/Input';
import Textarea from '../../components/form/Textarea';

import GlobalContext from '../../contexts/GlobalContext';
import validateUtil from '../../utils/validate';
import updateForm from '../../utils/updateForm';

import useApiJobPosition from '../../api/useApiJobPosition';

const INITIAL_FORM = {
  name: '',
  description: '',
  active: true,
  company: 0,
};

const JobPositionForm = () => {
  const { loading, globalState, toast } = useContext(GlobalContext);
  const { id } = useParams();
  const { apiJobPositionCreate, apiJobPositionGet, apiJobPositionUpdate } =
    useApiJobPosition();
  const navigate = useNavigate();

  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});
  const [addOrEdit, setAddOrEdit] = useState('Agregar');

  const getData = useCallback(async () => {
    try {
      const resp = await apiJobPositionGet(id);
      setForm(resp.data.data.attributes);
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (id) {
      setAddOrEdit('Editar');
      getData();
    }
  }, [id, getData]);

  const validate = () => {
    const validations = [
      {
        name: 'name',
        value: form.name,
        validations: { required: true },
      },
    ];
    const validateResult = validateUtil(validations);
    if (!validateResult.valid) {
      setErrors(validateResult.errors);
    }
    return validateResult.valid;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      form.company = globalState.user.company.id;
      if (id) {
        await apiJobPositionUpdate(id, form);
      } else {
        await apiJobPositionCreate(form);
      }
      navigate('/puestos');
      toast.fire({
        icon: 'success',
        title: `Puesto ${form.name} ${
          id ? 'actualizado' : 'creado'
        } exitosamente.`,
      });
    } catch (error) {
      console.log('error: ', error);
      setErrors({
        general: `Ocurrio un error al ${
          id ? 'actualizar' : 'crear'
        } el puesto.`,
      });
    }
  };

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setErrors({});
  };

  return (
    <Layout
      menu="jobPositions"
      title={`${addOrEdit} Puesto`}
      backUrl="/puestos"
    >
      <Card className="mt-4 form-view">
        <form onSubmit={submit}>
          <Input
            label="Nombre"
            id="name"
            name="name"
            value={form.name}
            required
            onChange={handleChange}
            helperText={errors.name}
          />
          <Textarea
            label="Descripción"
            id="description"
            name="description"
            value={form.description}
            required
            onChange={handleChange}
            helperText={errors.description}
          />
          {id && (
            <Checkbox
              id="active"
              name="active"
              checked={form.active}
              className="mb-4"
              onChange={handleChange}
            >
              Activo
            </Checkbox>
          )}
          {errors.general && (
            <div className="mb-2">
              <Text color="error" className="mb-4">
                {errors.general}
              </Text>
            </div>
          )}
          <Button type="submit" clickable={!loading}>
            {`${addOrEdit === 'Agregar' ? addOrEdit : 'Actualizar'} puesto`}
          </Button>
        </form>
      </Card>
    </Layout>
  );
};

export default JobPositionForm;
