import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PDFViewer,
  Page,
  Document,
  Image,
  Text,
  View,
} from '@react-pdf/renderer';
import * as dayjs from 'dayjs';
import { Button } from '@nextui-org/react';

import Layout from '../../../components/layout/Layout';
import ReactSelect from '../../../components/form/ReactSelect';
import styles from './styles';

import useApiReports from '../../../api/useApiReports';
import useApiEmployee from '../../../api/useApiEmployee';
import Input from '../../../components/form/Input';

import updateForm from '../../../utils/updateForm';

require('dayjs/locale/es-mx');

dayjs.locale('es-mx');

const OPTION_ALL = { value: '0', label: 'TODOS' };

const INITIAL_FORM = {
  employee: OPTION_ALL,
  initialDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  finalDate: dayjs().format('YYYY-MM-DD'),
};

const TrainerXEmployee = () => {
  const { apiCourseXEmployee } = useApiReports();
  const { apiEmployeeCombo } = useApiEmployee();

  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [form, setForm] = useState(INITIAL_FORM);

  const dataMemo = useMemo(() => {
    const d = [];
    for (const x of data) {
      const total =
        Math.round(
          (x.data.reduce(
            (accumulator, object) => accumulator + object.duration,
            0,
          ) /
            60) *
            100,
        ) / 100;
      d.push({ ...x, total });
    }
    return d;
  }, [data]);

  const getInitialData = useCallback(async () => {
    try {
      const resp = await apiEmployeeCombo();
      const empData = resp.data.data.reduce((accumulator, x) => {
        accumulator.push({
          value: x.id.toString(),
          label: `${x.attributes.number} - ${x.attributes.name} ${x.attributes.lastName}`,
        });
        return accumulator;
      }, []);
      setEmployees([OPTION_ALL, ...empData]);
    } catch (error) {
      console.log('error1: ', error); // eslint-disable-line
    }
  }, []); // eslint-disable-line

  const getData = async () => {
    try {
      const d = {
        employeeId: parseInt(form.employee.value, 10),
        initialDate: form.initialDate,
        finalDate: form.finalDate,
      };
      const resp = await apiCourseXEmployee(d);
      setData(resp.data);
    } catch (error) {
      console.log('error2: ', error); // eslint-disable-line
    }
  };

  useEffect(() => {
    getInitialData();
  }, []); // eslint-disable-line

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setData([]);
  };

  return (
    <Layout
      menu="dashboard"
      title="Reporte de entrenamientos por empleado"
      backUrl="/"
    >
      <div className="d-flex flex-column w-100 h-100 mt-4">
        <div className="report-filters">
          <ReactSelect
            label="Buscar empleado"
            id="employee"
            name="employee"
            options={employees}
            onChange={handleChange}
            value={form.employee}
            createLabel="Crear empleado"
          />
          <Input
            label="Fecha inicial"
            id="initialDate"
            name="initialDate"
            value={form.initialDate}
            required
            onChange={handleChange}
            type="date"
            className="bg-white"
          />
          <Input
            label="Fecha final"
            id="finalDate"
            name="finalDate"
            value={form.finalDate}
            required
            onChange={handleChange}
            type="date"
            className="bg-white"
          />
          <Button type="button" className="w-100" onClick={getData}>
            Generar reporte
          </Button>
        </div>
        {dataMemo.length > 0 && (
          <PDFViewer className="flex-1 mt-2 w-100">
            <Document>
              {dataMemo.map((emp) => (
                <Page style={styles.body} key={emp.id}>
                  <View style={styles.header} fixed>
                    <Image
                      style={styles.image}
                      src="https://cdn.shopify.com/s/files/1/0173/9279/5712/files/logo-full.png?v=1613740528"
                      fixed
                    />
                    <View style={styles.titleContainer}>
                      <Text style={styles.title}>
                        Entrenamientos por empleado
                      </Text>
                      <Text style={styles.date}>
                        {`Generado el ${dayjs().format(
                          'D [de] MMMM [del] YYYY',
                        )}`}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.infoContainer}>
                    <Text style={styles.label}># de reloj:</Text>
                    <Text style={styles.value}>{emp.number}</Text>
                  </View>
                  <View style={styles.infoContainer}>
                    <Text style={styles.label}>Empleado:</Text>
                    <Text style={styles.value}>
                      {`${emp.name} ${emp.last_name}`}
                    </Text>
                  </View>

                  <View style={styles.tableContainer}>
                    <View style={styles.tableHeader}>
                      <Text style={[styles.headerCell, styles.colDate]}>
                        Fecha de{'\n'}Entrenamiento
                      </Text>
                      <Text style={[styles.headerCell, styles.colCode]}>
                        Código
                      </Text>
                      <Text style={[styles.headerCell, styles.colName]}>
                        Nombre del{'\n'}Entrenamiento
                      </Text>
                      <Text style={[styles.headerCell, styles.colDesc]}>
                        Motivo del{'\n'}Entrenamiento
                      </Text>
                      <Text style={[styles.headerCell, styles.colInstructor]}>
                        Instructor
                      </Text>
                      <Text style={[styles.headerCell, styles.colDuration]}>
                        Duración en{'\n'}minutos
                      </Text>
                    </View>
                    {emp.data.map((x) => (
                      <View
                        style={styles.row}
                        key={`${emp.id}${x.id}`}
                        wrap={false}
                      >
                        <View style={[styles.cell, styles.colDate]}>
                          <Text>{dayjs(x.date).format('DD/MMM/YYYY')}</Text>
                        </View>
                        <View style={[styles.cell, styles.colCode]}>
                          <Text>{x.code}</Text>
                        </View>
                        <View style={[styles.cell, styles.colName]}>
                          <Text>{x.name}</Text>
                        </View>
                        <View style={[styles.cell, styles.colDesc]}>
                          <Text>{x.reason}</Text>
                        </View>
                        <View style={[styles.cell, styles.colInstructor]}>
                          <Text>{x.instructor}</Text>
                        </View>
                        <View style={[styles.cell, styles.colDuration]}>
                          <Text>{x.duration}</Text>
                        </View>
                      </View>
                    ))}
                    <View style={styles.row}>
                      <View
                        style={[
                          styles.cell,
                          styles.colTotal,
                          styles.tableFooter,
                        ]}
                      >
                        <Text>Total de hrs:</Text>
                      </View>
                      <View
                        style={[
                          styles.cell,
                          styles.colDuration,
                          styles.tableFooter,
                        ]}
                      >
                        <Text>{`${emp.total}hrs`}</Text>
                      </View>
                    </View>
                  </View>
                  <Text
                    style={styles.pageNumber}
                    render={(x) => `${x.pageNumber} / ${x.totalPages}`}
                    fixed
                  />
                </Page>
              ))}
            </Document>
          </PDFViewer>
        )}
      </div>
    </Layout>
  );
};

export default TrainerXEmployee;
