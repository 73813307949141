import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Table from '../../components/table/Table';
import Edit from '../../components/table/buttons/Edit';

import useApiEvaluationForm from '../../api/useApiEvaluationForm';

const EvaluationFormList = () => {
  const { apiEvaluationFormList } = useApiEvaluationForm();

  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    try {
      const resp = await apiEvaluationFormList();
      setData(resp.data.data);
    } catch (error) {
      console.log('error: ', error); // eslint-disable-line
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      { Header: 'Nombre', accessor: 'attributes.name' },
      {
        Header: 'Editar',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        headerClassName: 'text-center flex-center',
        Cell: (row) => (
          <Edit href={`/forma-evaluacion/editar/${row.row.original.id}`} /> // eslint-disable-line
        ),
      },
    ],
    [],
  );

  return (
    <Layout menu="evaluationForms" title="Forma de Evaluación">
      <Card className="mt-4">
        <Table
          columns={columns}
          data={data}
          addPath="/forma-evaluacion/agregar"
        />
      </Card>
    </Layout>
  );
};

export default EvaluationFormList;
