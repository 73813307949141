import React, { useContext } from 'react';
import { Button, Card } from '@nextui-org/react';

import CourseContext, { CourseProvider } from '../../contexts/CouseContext';

import Layout from '../../components/layout/Layout';
import GeneralData from './GeneralData';
import Topics from './topics/Topics';
import Employees from './employees/Employees';

const Component = () => {
  const { addOrEdit, submit } = useContext(CourseContext);

  return (
    <Layout menu="courses" title={`${addOrEdit} Curso`} backUrl="/cursos">
      <form onSubmit={submit}>
        <Card className="mt-4">
          <GeneralData />
          <Topics />
          <Employees />
          <Button type="submit" className="mt-5">
            {`${addOrEdit === 'Agregar' ? addOrEdit : 'Actualizar'} curso`}
          </Button>
        </Card>
      </form>
    </Layout>
  );
};

const CourseForm = () => (
  <CourseProvider>
    <Component />
  </CourseProvider>
);

export default CourseForm;
