import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiEmployee = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiEmployeeList = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/employees?sort[0]=name:asc&filters[active][$eq]=true&populate[department][fields][0]=name&populate[jobPosition][fields][0]=name&populate[turn][fields][0]=name',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEmployeeCombo = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/employees?sort[0]=name:asc&filters[active][$eq]=true&fields[0]=name&fields[1]=lastName&fields[2]=number',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEmployeeComboComplete = async () => {
    setLoading(true);
    try {
      return await API.get('/employes');
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEmployeeGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(
        `/employees/${id}?populate[department][fields][0]=name&populate[jobPosition][fields][0]=name&populate[turn][fields][0]=name`,
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEmployeeCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/employees', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEmployeeUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/employees/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiEmployeeImport = async (data) => {
    setLoading(true);
    try {
      return await API.post('/employees', data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiEmployeeList,
    apiEmployeeCombo,
    apiEmployeeComboComplete,
    apiEmployeeGet,
    apiEmployeeCreate,
    apiEmployeeUpdate,
    apiEmployeeImport,
  };
};

export default useApiEmployee;
