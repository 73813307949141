import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiCourse = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiCourseList = async () => {
    setLoading(true);
    try {
      return await API.get('/coursedetails');
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiCourseGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(`/coursedetails/${id}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  // Data for edition
  const apiCoursexGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(`/coursex/${id}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiCourseCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/courses', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiCourseDetailCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/course-details', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiCourseUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/courses/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiCourseDetailUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/course-details/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiCourseList,
    apiCourseGet,
    apiCourseCreate,
    apiCourseDetailCreate,
    apiCourseUpdate,
    apiCoursexGet,
    apiCourseDetailUpdate,
  };
};

export default useApiCourse;
