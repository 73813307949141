import React from 'react';
import { Text } from '@nextui-org/react';
import PropTypes from 'prop-types';
import logo from '../assets/img/isotype.png';

const Loader = ({ text, show }) => (
  <div className={`loader-wrapper ${show ? 'd-flex' : 'd-none'}`}>
    <img src={logo} alt="Tracos" className="logo" />
    <Text h3 className="text-center">
      {text || 'Cargando'}
    </Text>
  </div>
);

Loader.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool,
};

Loader.defaultProps = {
  text: '',
  show: false,
};

export default Loader;
