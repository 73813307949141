import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Grid } from '@nextui-org/react';
import {
  UsersIcon,
  DocumentTextIcon,
  ClockIcon,
} from '@heroicons/react/outline';

import Layout from '../components/layout/Layout';

const CardReport = ({ href, icon: Icon, text }) => (
  <Grid xs={12} sm={6} md={4} lg={3}>
    <Link to={href} className="w-100 h-100">
      <Card className="mt-2 d-flex flex-column flex-center w-100 h-100">
        <Icon className="card-icon" />
        <h4 className="text-center text-color">{text}</h4>
      </Card>
    </Link>
  </Grid>
);

const Dashboard = () => (
  <Layout menu="dashboard" title="Reportes">
    <Grid.Container gap={2}>
      <CardReport
        href="/reportes/entrenamiento-por-empleado"
        icon={UsersIcon}
        text="Entrenamiento por Empleado"
      />
      <CardReport
        href="/reportes/entrenamiento-general"
        icon={DocumentTextIcon}
        text="Entrenamiento general"
      />
      <CardReport
        href="/reportes/horas-de-entrenamiento-recibidas"
        icon={ClockIcon}
        text="Horas de entrenamiento recibidas"
      />
    </Grid.Container>
  </Layout>
);

CardReport.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
};

export default Dashboard;
