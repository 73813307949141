import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiTurn = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiTurnList = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/turns?sort[0]=name:asc&filters[active][$eq]=true',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTurnCombo = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/turns?sort[0]=name:asc&filters[active][$eq]=true&fields[0]=name&fields[1]=code',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTurnGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(`/turns/${id}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTurnCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/turns', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiTurnUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/turns/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiTurnList,
    apiTurnCombo,
    apiTurnGet,
    apiTurnCreate,
    apiTurnUpdate,
  };
};

export default useApiTurn;
