import React, { createContext, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import GlobalReducer, { types } from './GlobalReducer';

// Creation of the context
const GlobalContext = createContext();

// Default value for the initial state of the user
const initialUser = {};

/**
 * Check if there is a user stored in local storage
 * If not, declare the default values
 * @returns Initial state object
 */
const initialState = () => {
  // eslint-disable-next-line
  let user = localStorage.getItem('user');
  const isAuthenticated = !!user;
  user = user ? JSON.parse(user) : initialUser;
  return {
    isAuthenticated,
    user,
    loading: false,
    token: '',
  };
};

/**
 * Allows consuming components to subscribe to context changes
 * @param {component} children
 */
const GlobalProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(GlobalReducer, initialState());
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('dashboard');

  const updateUserData = (name, value) => {
    dispatch({
      type: types.updateUser,
      name,
      value,
    });
  };

  const signUp = (userInfo) => {
    dispatch({ type: types.signUp, userInfo });
  };

  const logout = () => {
    dispatch({
      type: types.logout,
      user: initialUser,
    });
  };

  const setLoading = (value) => {
    dispatch({ type: types.setLoading, value });
  };

  const setToken = (token) => {
    dispatch({ type: types.setToken, token });
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (t) => {
      t.addEventListener('mouseenter', Swal.stopTimer);
      t.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  const values = useMemo(
    () => ({
      globalState,
      updateUserData,
      signUp,
      logout,
      loading: globalState.loading,
      setLoading,
      token: globalState.token,
      setToken,
      menuOpen,
      toggleMenu,
      selectedMenu,
      setSelectedMenu,
      toast,
    }),
    [globalState, menuOpen, selectedMenu, toast],
  );

  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { GlobalProvider };
export default GlobalContext;
