import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiUser = () => {
  const { setLoading } = useContext(GlobalContext);

  const apiUserList = async () => {
    setLoading(true);
    const API = Constructor(); // Create instance
    try {
      return await API.get('/users');
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Endpoint to login
   * @param {string} identifier System user or e-mail
   * @param {string} password User password
   * @returns Promise
   */
  const apiUserMe = async () => {
    setLoading(true);
    const API = Constructor(); // Create instance
    try {
      return await API.get('/users/me?populate=*');
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiUserGet = async (id) => {
    setLoading(true);
    const API = Constructor(); // Create instance
    try {
      return await API.get(`/users/${id}?populate=*`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiUserCreate = async (data) => {
    setLoading(true);
    const API = Constructor(); // Create instance
    try {
      return await API.post('/users', data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiUserUpdate = async (id, data) => {
    setLoading(true);
    const API = Constructor(); // Create instance
    try {
      return await API.put(`/users/${id}`, data);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiUserList,
    apiUserMe,
    apiUserGet,
    apiUserCreate,
    apiUserUpdate,
  };
};

export default useApiUser;
