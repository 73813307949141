import React, { useContext, useMemo, useState } from 'react';
import { Grid, Text, Button } from '@nextui-org/react';
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/outline';

import CourseContext, { EMPTY_CBO_OBJ } from '../../../contexts/CouseContext';

import ReactSelect from '../../../components/form/ReactSelect';
import Subtitle from '../../../components/Subtitle';
import Table from '../../../components/table/Table';

const Employees = () => {
  const {
    employees,
    setEmployees,
    employeesAdded,
    setEmployeesAdded,
    errors,
    setErrors,
  } = useContext(CourseContext);
  const [employee, setEmployee] = useState(EMPTY_CBO_OBJ);
  const [employeeAdded, setEmployeeAdded] = useState(EMPTY_CBO_OBJ);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsAdded, setSelectedRowsAdded] = useState([]);

  const handleChange = async (e) => {
    const {
      target: { value },
    } = e;
    setEmployeesAdded([...employeesAdded, value]);
    const filterEmployees = employees.filter((x) => x.id !== value.id);
    setEmployees(filterEmployees);
    setEmployee(EMPTY_CBO_OBJ);
    setErrors({});
  };

  const handleChangeAdded = async (e) => {
    const {
      target: { value },
    } = e;
    setEmployees([...employees, value]);
    const filterEmployees = employeesAdded.filter((x) => x.id !== value.id);
    setEmployeesAdded(filterEmployees);
    setEmployeeAdded(EMPTY_CBO_OBJ);
    setErrors({});
  };

  const addSelection = () => {
    let available = [...employees];
    const added = [...employeesAdded];
    for (const x of selectedRows) {
      added.push(x.original);
      available = available.filter((y) => y.id !== x.original.id);
    }
    setEmployees(available);
    setEmployeesAdded(added);
  };

  const removeSelection = () => {
    const available = [...employees];
    let added = [...employeesAdded];
    for (const x of selectedRowsAdded) {
      available.push(x.original);
      added = added.filter((y) => y.id !== x.original.id);
    }
    setEmployees(available);
    setEmployeesAdded(added);
  };

  const handleCreateEmployee = async (name) => {
    console.log('name: ', name);
  };

  const columns = useMemo(
    () => [
      { Header: 'Número', accessor: 'number' },
      { Header: 'Nombre', accessor: 'name' },
      { Header: 'Apellido', accessor: 'lastName' },
      { Header: 'Depto', accessor: 'department' },
      { Header: 'Puesto', accessor: 'jobPosition' },
      { Header: 'Turno', accessor: 'turn' },
    ],
    [],
  );

  return (
    <>
      <Subtitle className="mt-4">Empleados en el curso</Subtitle>
      <Grid.Container gap={2} justify="center">
        <Grid xs={12} sm={6} direction="column">
          <Text h5 className="mb-4">
            Empleados disponibles (<strong>{employees.length}</strong>)
          </Text>
          <ReactSelect
            label="Buscar empleado"
            id="employee"
            name="employee"
            options={employees}
            onCreateOption={handleCreateEmployee}
            onChange={handleChange}
            value={employee}
            createLabel="Crear empleado"
            classNameWrapper="pb-4"
            helperText={errors.employee}
          />
          <div className="mb-2 z-0 d-none d-lg-block">
            <Button
              auto
              type="button"
              icon={<ChevronDoubleRightIcon width="1.5rem" />}
              className="mr-2 mr-lg-0 mb-0 mb-lg-2 float-right"
              onClick={addSelection}
              disabled={selectedRows.length === 0}
            />
          </div>
          <div className="table-employees">
            <Table
              columns={columns}
              data={employees}
              hideGlobalFilter
              hidePagination
              selectable
              setSelectedRows={setSelectedRows}
              pageSize={10000}
            />
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={0}
          justify="center"
          alignItems="center"
          // className="wrapper-buttons-send"
        >
          <Button
            auto
            type="button"
            icon={<ChevronDoubleDownIcon width="1.5rem" />}
            className="mr-2 mr-lg-0 mb-0 mb-lg-2"
            onClick={addSelection}
            disabled={selectedRows.length === 0}
          />
          <Button
            auto
            type="button"
            icon={<ChevronDoubleUpIcon width="1.5rem" />}
            className="ml-2 ml-lg-0 mt-0 mt-lg-2"
            onClick={removeSelection}
            disabled={selectedRowsAdded.length === 0}
          />
        </Grid>
        <Grid xs={12} sm={6} direction="column">
          <Text h5 className="mb-4">
            Empleados agregados (<strong>{employeesAdded.length}</strong>)
          </Text>
          <ReactSelect
            label="Buscar empleado"
            id="employeeAdded"
            name="employeeAdded"
            options={employeesAdded}
            onCreateOption={handleCreateEmployee}
            onChange={handleChangeAdded}
            value={employeeAdded}
            createLabel="Crear empleado"
            classNameWrapper="pb-4"
            helperText={errors.employee}
          />
          <div className="mb-2 z-0 d-none d-lg-block">
            <Button
              auto
              type="button"
              icon={<ChevronDoubleLeftIcon width="1.5rem" className="z-10" />}
              className="ml-2 ml-lg-0 mt-0 mt-lg-2"
              onClick={removeSelection}
              disabled={selectedRowsAdded.length === 0}
            />
          </div>
          <div className="table-employees">
            <Table
              columns={columns}
              data={employeesAdded}
              hideGlobalFilter
              hidePagination
              selectable
              setSelectedRows={setSelectedRowsAdded}
              pageSize={10000}
            />
          </div>
        </Grid>
      </Grid.Container>
    </>
  );
};

export default Employees;
