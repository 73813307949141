/* eslint react/prop-types: "off" */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import {
  ArrowSmUpIcon,
  ArrowSmDownIcon,
  PlusIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { Pagination, Button } from '@nextui-org/react';

import DefaultColumnFilter from './filters/DefaultColumnFilter';
import GlobalFilter from './filters/GlobalFilter';

function fuzzyTextFilterFn(pRows, id, filterValue) {
  return matchSorter(pRows, filterValue, {
    keys: [(row) => row.values[id]],
  });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  },
);

const Table = ({
  columns,
  data,
  addPath,
  uploadPath,
  hideGlobalFilter,
  hidePagination,
  pageSize,
  selectable,
  setSelectedRows,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    state: { globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    pageCount,
    gotoPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((cols) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...cols,
        ]);
      }
    },
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows);
  }, [selectedFlatRows]); // eslint-disable-line react-hooks/exhaustive-deps

  const isSorted = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? (
        <ArrowSmDownIcon className="sort" />
      ) : (
        <ArrowSmUpIcon className="sort" />
      );
    }
    return '';
  };

  return (
    <>
      {(addPath || !hideGlobalFilter || uploadPath) && (
        <div className="add-search">
          {(addPath || uploadPath) && (
            <div className="add">
              {addPath && (
                <Link to={addPath}>
                  <Button
                    auto
                    icon={<PlusIcon width="1rem" />}
                    className="mobile"
                  />
                  <Button icon={<PlusIcon width="1rem" />} className="desktop">
                    Agregar
                  </Button>
                </Link>
              )}
              {uploadPath && (
                <Link to={uploadPath}>
                  <Button
                    auto
                    icon={<UploadIcon width="1rem" />}
                    className="mobile"
                  />
                  <Button
                    icon={<UploadIcon width="1rem" />}
                    className="desktop"
                  >
                    Importar
                  </Button>
                </Link>
              )}
            </div>
          )}
          {!hideGlobalFilter && (
            <div className="search">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
          )}
        </div>
      )}
      <div className="table-responsive">
        <table {...getTableProps()} className="table table-striped">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span
                      className={`d-flex${
                        column.headerClassName
                          ? ` ${column.headerClassName}`
                          : ''
                      }`}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {isSorted(column)}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {/* Render the columns filter UI */}
                    {column.canFilter ? column.render('Filter') : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data.length > 0 ? (
              <>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={visibleColumns.length} className="text-center">
                  No existen registros
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <br />
      {/* <div>Showing the first 20 results of {rows.length} rows</div> */}
      {!hidePagination && (
        <Pagination
          total={pageCount}
          initialPage={1}
          onChange={(p) => gotoPage(p - 1)}
        />
      )}
    </>
  );
};

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  addPath: PropTypes.string,
  uploadPath: PropTypes.string,
  hideGlobalFilter: PropTypes.bool,
  hidePagination: PropTypes.bool,
  pageSize: PropTypes.number,
  selectable: PropTypes.bool,
  setSelectedRows: PropTypes.func,
};

Table.defaultProps = {
  addPath: '',
  uploadPath: '',
  hideGlobalFilter: false,
  hidePagination: false,
  pageSize: 10,
  selectable: false,
  setSelectedRows: () => {},
};

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.any,
};

IndeterminateCheckbox.defaultProps = {
  indeterminate: undefined,
};

export default Table;
