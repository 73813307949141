import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiDepartment = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiDepartmentList = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/departments?sort[0]=name:asc&filters[active][$eq]=true',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiDepartmentCombo = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/departments?sort[0]=name:asc&filters[active][$eq]=true&fields[0]=name&fields[1]=code',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiDepartmentGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(`/departments/${id}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiDepartmentCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/departments', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiDepartmentUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/departments/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiDepartmentList,
    apiDepartmentCombo,
    apiDepartmentGet,
    apiDepartmentCreate,
    apiDepartmentUpdate,
  };
};

export default useApiDepartment;
