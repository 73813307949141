import React from 'react';
import { Input as InputNextUI } from '@nextui-org/react';
import PropTypes from 'prop-types';

const InputPassword = ({ classNameWrapper, helperText, ...props }) => (
  <div
    className={`form-control${classNameWrapper ? ` ${classNameWrapper}` : ''}`}
  >
    <InputNextUI.Password
      bordered
      fullWidth
      color={helperText ? 'error' : 'primary'}
      helperText={helperText}
      helperColor="error"
      {...props}
    />
  </div>
);

InputPassword.propTypes = {
  classNameWrapper: PropTypes.string,
  helperText: PropTypes.string,
};

InputPassword.defaultProps = {
  classNameWrapper: '',
  helperText: '',
};

export default InputPassword;
