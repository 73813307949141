import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text } from '@nextui-org/react';

import GlobalContext from '../../../contexts/GlobalContext';

import { authorized } from '../../../utils/utils';

const MenuItem = ({ href, icon: Icon, text, slug, permissionId }) => {
  const {
    selectedMenu,
    globalState: { user },
  } = useContext(GlobalContext);

  if (!authorized(permissionId) && permissionId !== 0 && !user.admin) {
    return null;
  }

  return (
    <li
      className={`menu-item ${
        selectedMenu === slug ? 'menu-item-selected' : ''
      }`}
    >
      <Link to={href} className="menu-link">
        <Icon className="menu-icon" />
        <Text color={selectedMenu === slug ? '#398AFE' : '#fefefe'} span>
          {text}
        </Text>
      </Link>
    </li>
  );
};

MenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  permissionId: PropTypes.number,
};

MenuItem.defaultProps = {
  permissionId: 0,
};

export default MenuItem;
