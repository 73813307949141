/**
 * Use: await sleep(5000);
 * @param {number} ms Number of milliseconds to sleep
 * @returns Promise
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms)); // eslint-disable-line

/**
 * Check the list of permissions in search of the requested permission
 * @param {number} permissionId Id of the requested permission
 * @returns Boolean
 */
export const authorized = (permissionId) => {
  if (permissionId) {
    return true;
  }
  return true;
};
