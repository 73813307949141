import React, { useContext, useMemo, useState } from 'react';
import { Button, Card, Text } from '@nextui-org/react';
import { PlusIcon, MinusIcon } from '@heroicons/react/outline';
import { useDropzone } from 'react-dropzone';

import useApiEmployee from '../../../api/useApiEmployee';

import EmployeeImportContext, {
  INITIAL_EMPLOYEES,
} from '../../../contexts/EmployeeImportContext';

const btnGrayCss = {
  background: '$gray200', // colors.gray800
  color: '$gray500',
  '&:hover': {
    background: '$gray300',
    color: '$gray600',
  },
  '&:active': {
    background: '$gray200',
  },
};

const Form = () => {
  const { setEmployees, setProcessed } = useContext(EmployeeImportContext);
  const { apiEmployeeImport } = useApiEmployee();

  const [action, setAction] = useState('registration'); // registration || termination
  const [errors, setErrors] = useState({});

  const clearErrors = () => {
    setErrors({});
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: clearErrors,
  });

  const submit = async () => {
    if (acceptedFiles.length === 0) {
      setErrors({ file: 'Selecciona un archivo.' });
      return;
    }

    if (!acceptedFiles[0].path.includes('.csv')) {
      setErrors({ file: 'El archivo seleccionado no esta en formato CSV.' });
      return;
    }

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('action', action);
    formData.append('file', acceptedFiles[0]);
    formData.append('import', 'true');

    try {
      const resp = await apiEmployeeImport(formData);
      setEmployees(resp.data);
      setProcessed(true);
    } catch (error) {
      console.log('error: ', error);
      setEmployees(INITIAL_EMPLOYEES);
    }
  };

  const files = useMemo(
    () =>
      acceptedFiles.map((f) => (
        <li key={f.path}>
          {f.path} - {f.size} bytes
        </li>
      )),
    [acceptedFiles],
  );

  return (
    <Card className="mt-4">
      <div className="d-flex flex-column flex-sm-row mb-4">
        <Button
          color="primary"
          css={action !== 'registration' ? btnGrayCss : {}}
          flat={action === 'registration'}
          light={action !== 'registration'}
          icon={<PlusIcon width="1rem" />}
          className="flex-1 mr-sm-2 mb-2 mb-sm-0"
          onClick={() => setAction('registration')}
        >
          Alta de empleados
        </Button>
        <Button
          color="primary"
          css={action !== 'termination' ? btnGrayCss : {}}
          flat={action === 'termination'}
          light={action !== 'termination'}
          icon={<MinusIcon width="1rem" />}
          className="flex-1 ml-sm-2"
          onClick={() => setAction('termination')}
        >
          Baja de empleados
        </Button>
      </div>
      <div className="mb-4 d-flex flex-column">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>
            Arrastra y suelta algún archivo <b>.CSV</b>, o haz click para
            seleccionarlo.
          </p>
          {acceptedFiles.length > 0 && <ul>{files}</ul>}
        </div>
        {errors.file && (
          <Text color="error" size={12}>
            {errors.file}
          </Text>
        )}
      </div>
      <Button
        color="primary"
        icon={<PlusIcon width="1rem" />}
        className="flex-1 ml-sm-2"
        onClick={submit}
      >
        Importar&nbsp;
        <strong>{action === 'registration' ? 'ALTAS' : 'BAJAS'}</strong>
        &nbsp;de empleados
      </Button>
    </Card>
  );
};

export default Form;
