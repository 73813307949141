import React from 'react';
import { Input as InputNextUI } from '@nextui-org/react';
import PropTypes from 'prop-types';

const Input = ({
  classNameWrapper,
  helperText,
  ...props
}) => (
  <div className={`form-control${classNameWrapper ? ` ${classNameWrapper}` : ''}`}>
    <InputNextUI
      bordered
      fullWidth
      color={helperText ? 'error' : 'primary'}
      helperText={helperText}
      helperColor="error"
      {...props}
    />
  </div>
);

Input.propTypes = {
  classNameWrapper: PropTypes.string,
  helperText: PropTypes.string,
};

Input.defaultProps = {
  classNameWrapper: '',
  helperText: '',
};

export default Input;
