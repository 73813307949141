import React, { useCallback, useEffect, useState } from 'react';
import {
  PDFViewer,
  Page,
  Document,
  Image,
  Text,
  View,
} from '@react-pdf/renderer';
import * as dayjs from 'dayjs';
import { Button } from '@nextui-org/react';

import Layout from '../../../components/layout/Layout';
import ReactSelect from '../../../components/form/ReactSelect';
import styles from './styles';

import useApiReports from '../../../api/useApiReports';
import useApiEmployee from '../../../api/useApiEmployee';
import Input from '../../../components/form/Input';

import updateForm from '../../../utils/updateForm';

require('dayjs/locale/es-mx');

dayjs.locale('es-mx');

const OPTION_ALL = { value: '0', label: 'TODOS' };

const INITIAL_FORM = {
  employee: OPTION_ALL,
  initialDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  finalDate: dayjs().format('YYYY-MM-DD'),
};

const TrainingHoursReceived = () => {
  const { apiTrainingHoursReceived } = useApiReports();
  const { apiEmployeeCombo } = useApiEmployee();

  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [form, setForm] = useState(INITIAL_FORM);
  const [total, setTotal] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const getInitialData = useCallback(async () => {
    try {
      const resp = await apiEmployeeCombo();
      const empData = resp.data.data.reduce((accumulator, x) => {
        accumulator.push({
          value: x.id.toString(),
          label: `${x.attributes.number} - ${x.attributes.name} ${x.attributes.lastName}`,
        });
        return accumulator;
      }, []);
      setEmployees([OPTION_ALL, ...empData]);
    } catch (error) {
      console.log('error1: ', error); // eslint-disable-line
    }
  }, []); // eslint-disable-line

  const getData = async () => {
    try {
      const d = {
        employeeId: parseInt(form.employee.value, 10),
        initialDate: form.initialDate,
        finalDate: form.finalDate,
      };
      const resp = await apiTrainingHoursReceived(d);
      setData(resp.data);
      const tot = resp.data.reduce((a, o) => a + parseInt(o.hours, 10), 0);
      setTotal(tot);
      setTotalHours(Math.round((tot / 60) * 100) / 100);
    } catch (error) {
      console.log('error2: ', error); // eslint-disable-line
    }
  };

  useEffect(() => {
    getInitialData();
  }, []); // eslint-disable-line

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setData([]);
  };

  return (
    <Layout
      menu="dashboard"
      title="Reporte de horas de entrenamiento recibidas"
      backUrl="/"
    >
      <div className="d-flex flex-column w-100 h-100 mt-4">
        <div className="report-filters">
          <ReactSelect
            label="Buscar empleado"
            id="employee"
            name="employee"
            options={employees}
            onChange={handleChange}
            value={form.employee}
            createLabel="Crear empleado"
          />
          <Input
            label="Fecha inicial"
            id="initialDate"
            name="initialDate"
            value={form.initialDate}
            required
            onChange={handleChange}
            type="date"
            className="bg-white"
          />
          <Input
            label="Fecha final"
            id="finalDate"
            name="finalDate"
            value={form.finalDate}
            required
            onChange={handleChange}
            type="date"
            className="bg-white"
          />
          <Button type="button" className="w-100" onClick={getData}>
            Generar reporte
          </Button>
        </div>
        {data.length > 0 && (
          <PDFViewer className="flex-1 mt-2 w-100">
            <Document>
              <Page style={styles.body}>
                <View style={styles.header} fixed>
                  <Image
                    style={styles.image}
                    src="https://cdn.shopify.com/s/files/1/0173/9279/5712/files/logo-full.png?v=1613740528"
                    fixed
                  />
                  <View style={styles.titleContainer}>
                    <Text style={styles.title}>
                      Horas de entrenamiento recibidas
                    </Text>
                    <Text style={styles.date}>
                      {`Periodo: ${dayjs(form.initialDate).format(
                        'DD/MMM/YYYY',
                      )} - ${dayjs(form.finalDate).format('DD/MMM/YYYY')}`}
                    </Text>
                    <Text style={styles.date}>
                      {`Generado el ${dayjs().format(
                        'D [de] MMMM [del] YYYY',
                      )}`}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableContainer}>
                  <View style={styles.tableHeader}>
                    <Text style={[styles.headerCell, styles.colCode]}>
                      # de reloj
                    </Text>
                    <Text style={[styles.headerCell, styles.colName]}>
                      Nombre del empleado
                    </Text>
                    <Text style={[styles.headerCell, styles.colDuration]}>
                      Minutos de entrenamiento recibidos
                    </Text>
                  </View>
                  {data.map((x) => (
                    <View style={styles.row} key={x.id} wrap={false}>
                      <View style={[styles.cell, styles.colCode]}>
                        <Text>{x.number}</Text>
                      </View>
                      <View style={[styles.cell, styles.colName]}>
                        <Text>{`${x.name} ${x.last_name}`}</Text>
                      </View>
                      <View style={[styles.cell, styles.colDuration]}>
                        <Text>{x.hours}</Text>
                      </View>
                    </View>
                  ))}
                  <View style={styles.row}>
                    <View
                      style={[styles.cell, styles.colTotal, styles.tableFooter]}
                    >
                      <Text>Total de minutos:</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        styles.colDuration,
                        styles.tableFooter,
                      ]}
                    >
                      <Text>{`${total} minutos`}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View
                      style={[styles.cell, styles.colTotal, styles.tableFooter]}
                    >
                      <Text>Total de horas:</Text>
                    </View>
                    <View
                      style={[
                        styles.cell,
                        styles.colDuration,
                        styles.tableFooter,
                      ]}
                    >
                      <Text>{`${totalHours} horas`}</Text>
                    </View>
                  </View>
                </View>
                <Text
                  style={styles.pageNumber}
                  render={(x) => `${x.pageNumber} / ${x.totalPages}`}
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        )}
      </div>
    </Layout>
  );
};

export default TrainingHoursReceived;
