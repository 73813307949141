import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Components
import Dashboard from '../pages/Dashboard';
import Error404 from '../pages/Error404';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import DepartmentList from '../pages/departments/DepartmentList';
import DepartmentForm from '../pages/departments/DepartmentForm';
import UserList from '../pages/users/UserList';
import UserForm from '../pages/users/UserForm';
import TurnList from '../pages/turns/TurnList';
import TurnForm from '../pages/turns/TurnForm';
import JobPositionList from '../pages/jobPositions/JobPositionList';
import JobPositionForm from '../pages/jobPositions/JobPositionForm';
import InstructorList from '../pages/instructors/InstructorList';
import InstructorForm from '../pages/instructors/InstructorForm';
import ReceiverList from '../pages/receivers/ReceiverList';
import ReceiverForm from '../pages/receivers/ReceiverForm';
import EmployeeList from '../pages/employees/EmployeeList';
import EmployeeForm from '../pages/employees/EmployeeForm';
import EmployeeImport from '../pages/employees/import/EmployeeImport';
import TrainingList from '../pages/trainings/TrainingList';
import TrainingForm from '../pages/trainings/TrainingForm';
import EvaluationFormList from '../pages/evaluationForms/EvaluationFormList';
import EvaluationFormForm from '../pages/evaluationForms/EvaluationFormForm';
import CourseList from '../pages/courses/CourseList';
import CourseForm from '../pages/courses/CourseForm';
import TrainerXEmployee from '../pages/reports/TrainerXEmployee/TrainerXEmployee';
import GeneralTraining from '../pages/reports/GeneralTraining/GeneralTraining';
import TrainingHoursReceived from '../pages/reports/TrainingHoursReceived/TrainingHoursReceived';
// Components End

/**
 * Component for application navigation
 * consists of private and public routes
 */
const AppRouter = () => (
  <Routes>
    <Route path="/login" element={<PublicRoute component={Login} />} />
    <Route path="/" element={<PrivateRoute component={Dashboard} />} />

    {/* Departments */}
    <Route
      path="/departamentos"
      element={<PrivateRoute component={DepartmentList} />}
    />
    <Route
      path="/departamentos/agregar"
      element={<PrivateRoute component={DepartmentForm} />}
    />
    <Route
      path="/departamentos/editar/:id"
      element={<PrivateRoute component={DepartmentForm} />}
    />

    {/* Users */}
    <Route path="/usuarios" element={<PrivateRoute component={UserList} />} />
    <Route
      path="/usuarios/agregar"
      element={<PrivateRoute component={UserForm} />}
    />
    <Route
      path="/usuarios/editar/:id"
      element={<PrivateRoute component={UserForm} />}
    />

    {/* Turns */}
    <Route path="/turnos" element={<PrivateRoute component={TurnList} />} />
    <Route
      path="/turnos/agregar"
      element={<PrivateRoute component={TurnForm} />}
    />
    <Route
      path="/turnos/editar/:id"
      element={<PrivateRoute component={TurnForm} />}
    />

    {/* JobPositions */}
    <Route
      path="/puestos"
      element={<PrivateRoute component={JobPositionList} />}
    />
    <Route
      path="/puestos/agregar"
      element={<PrivateRoute component={JobPositionForm} />}
    />
    <Route
      path="/puestos/editar/:id"
      element={<PrivateRoute component={JobPositionForm} />}
    />

    {/* Instructors */}
    <Route
      path="/instructores"
      element={<PrivateRoute component={InstructorList} />}
    />
    <Route
      path="/instructores/agregar"
      element={<PrivateRoute component={InstructorForm} />}
    />
    <Route
      path="/instructores/editar/:id"
      element={<PrivateRoute component={InstructorForm} />}
    />

    {/* Receivers */}
    <Route
      path="/destinatarios"
      element={<PrivateRoute component={ReceiverList} />}
    />
    <Route
      path="/destinatarios/agregar"
      element={<PrivateRoute component={ReceiverForm} />}
    />
    <Route
      path="/destinatarios/editar/:id"
      element={<PrivateRoute component={ReceiverForm} />}
    />

    {/* Employees */}
    <Route
      path="/empleados"
      element={<PrivateRoute component={EmployeeList} />}
    />
    <Route
      path="/empleados/agregar"
      element={<PrivateRoute component={EmployeeForm} />}
    />
    <Route
      path="/empleados/editar/:id"
      element={<PrivateRoute component={EmployeeForm} />}
    />
    <Route
      path="/empleados/importar"
      element={<PrivateRoute component={EmployeeImport} />}
    />

    {/* Trainings */}
    <Route
      path="/entrenamientos"
      element={<PrivateRoute component={TrainingList} />}
    />
    <Route
      path="/entrenamientos/agregar"
      element={<PrivateRoute component={TrainingForm} />}
    />
    <Route
      path="/entrenamientos/editar/:id"
      element={<PrivateRoute component={TrainingForm} />}
    />

    {/* EvaluationForms */}
    <Route
      path="/forma-evaluacion"
      element={<PrivateRoute component={EvaluationFormList} />}
    />
    <Route
      path="/forma-evaluacion/agregar"
      element={<PrivateRoute component={EvaluationFormForm} />}
    />
    <Route
      path="/forma-evaluacion/editar/:id"
      element={<PrivateRoute component={EvaluationFormForm} />}
    />

    {/* Courses */}
    <Route path="/cursos" element={<PrivateRoute component={CourseList} />} />
    <Route
      path="/cursos/agregar"
      element={<PrivateRoute component={CourseForm} />}
    />
    <Route
      path="/cursos/editar/:id"
      element={<PrivateRoute component={CourseForm} />}
    />

    {/* Reports */}
    <Route
      path="/reportes/entrenamiento-por-empleado"
      element={<PrivateRoute component={TrainerXEmployee} />}
    />
    <Route
      path="/reportes/entrenamiento-general"
      element={<PrivateRoute component={GeneralTraining} />}
    />
    <Route
      path="/reportes/horas-de-entrenamiento-recibidas"
      element={<PrivateRoute component={TrainingHoursReceived} />}
    />

    {/* ROUTES */}

    <Route path="*" element={<Error404 />} />
  </Routes>
);

export default AppRouter;
