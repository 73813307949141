import React from 'react';
import { Grid } from '@nextui-org/react';

import Subtitle from '../../../components/Subtitle';
import Form from './Form';
import Table from './Table';

const Topics = () => (
  <>
    <Subtitle>Temas a cursar</Subtitle>
    <Grid.Container gap={2} justify="center">
      <Form />
      <Table />
    </Grid.Container>
  </>
);

export default Topics;
