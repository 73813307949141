import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Text } from '@nextui-org/react';
import EmployeeImportContext from '../../../contexts/EmployeeImportContext';

const Table = ({ title, type }) => {
  const { employees, processed } = useContext(EmployeeImportContext);

  if (!processed) return null;

  return (
    <Grid xs={12} md={6} lg={4} className="flex-column mt-4">
      <Text h4>{title}</Text>
      <Card className="mt-4">
        <div className="table-responsive w-100">
          <table className="table table-striped ">
            <thead>
              <tr>
                <th>Número</th>
                <th>Nombre</th>
                <th>Apellido</th>
                {type !== 'added' && <th>Motivo</th>}
              </tr>
            </thead>
            <tbody>
              {employees[type].length > 0 ? (
                <>
                  {employees[type].map((x) => (
                    <tr key={x.number}>
                      <td>{x.number}</td>
                      <td>{x.name}</td>
                      <td>{x.lastName}</td>
                      {type !== 'added' && <td>{x.turn}</td>}
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan={type === 'added' ? '3' : '4'}
                    className="text-center"
                  >
                    No existen registros
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </Grid>
  );
};

Table.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['added', 'notAdded', 'ignored']),
};
Table.defaultProps = {
  type: 'added',
};

export default Table;
