import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text } from '@nextui-org/react';
import { ArrowLeftIcon } from '@heroicons/react/outline';

import GlobalContext from '../../contexts/GlobalContext';

import Header from './Header';
import SideBar from './sidebar/Sidebar';
import Loader from '../Loader';

const Layout = ({ menu, children, title, backUrl }) => {
  const { setSelectedMenu, loading, loadingText } = useContext(GlobalContext);

  useEffect(() => {
    setSelectedMenu(menu);
  }, [menu, setSelectedMenu]);

  return (
    <div className="app">
      <Header />
      <SideBar />
      <main role="main">
        <div className="d-flex align-items-center">
          {backUrl && (
            <Link to={backUrl} className="d-flex mr-2">
              <ArrowLeftIcon width="1.5rem" />
            </Link>
          )}
          <Text h3>{title}</Text>
        </div>
        {children}
      </main>
      <footer className="footer" />
      <Loader show={loading} text={loadingText} />
    </div>
  );
};

Layout.propTypes = {
  menu: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  backUrl: PropTypes.string,
};

Layout.defaultProps = {
  backUrl: '',
};

export default Layout;
