import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiJobPosition = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiJobPositionList = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/job-positions?sort[0]=name:asc&filters[active][$eq]=true',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiJobPositionCombo = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/job-positions?sort[0]=name:asc&filters[active][$eq]=true&fields[0]=name',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiJobPositionGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(`/job-positions/${id}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiJobPositionCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/job-positions', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiJobPositionUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/job-positions/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiJobPositionList,
    apiJobPositionCombo,
    apiJobPositionGet,
    apiJobPositionCreate,
    apiJobPositionUpdate,
  };
};

export default useApiJobPosition;
