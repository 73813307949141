import React from 'react';
import { Text } from '@nextui-org/react';
import ReactSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

const selectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    height: '2.75rem',
    borderRadius: '0.75rem',
    borderWidth: '0.125rem',
    borderColor: isFocused ? '#0070F3' : '#EAEAEA',
    fontSize: '0.875rem',
    color: '#333',
    marginTop: '0.125rem',
    ':hover': {
      borderColor: '#0070F3',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#C1C1C1',
    fontSize: '0.875rem',
  }),
  option: (styles) => ({ ...styles, fontSize: '0.875rem' }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
};

const Select = ({
  classNameWrapper,
  helperText,
  id,
  label,
  onChange,
  name,
  placeholder,
  createLabel,
  ...props
}) => (
  <div
    className={`form-control${classNameWrapper ? ` ${classNameWrapper}` : ''}`}
  >
    {label && (
      <Text size={14} htmlFor={id} as="label">
        {label}
      </Text>
    )}
    <ReactSelect
      id={id}
      name={name}
      styles={selectStyles}
      placeholder={placeholder}
      onChange={(value) => onChange({ target: { name, value } })}
      formatCreateLabel={(userInput) => `${createLabel} "${userInput}"`}
      {...props}
    />
    {helperText && (
      <Text small color="error" size={10} style={{ marginLeft: '0.625rem' }}>
        {helperText}
      </Text>
    )}
  </div>
);

Select.propTypes = {
  classNameWrapper: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  createLabel: PropTypes.string,
};

Select.defaultProps = {
  classNameWrapper: '',
  helperText: '',
  label: '',
  onChange: () => {},
  placeholder: '--Selecciona--',
  createLabel: 'Crear',
};

export default Select;
