import React, { useContext } from 'react';
import { Text } from '@nextui-org/react';
import { LogoutIcon } from '@heroicons/react/outline';
import { HeartIcon } from '@heroicons/react/solid';

import GlobalContext from '../../../contexts/GlobalContext';

const Footer = () => {
  const { logout } = useContext(GlobalContext);

  return (
    <div className="sidebar-footer">
      <div
        className="menu-item"
        onClick={logout}
        role="button"
        tabIndex={0}
      >
        <div className="menu-link">
          <LogoutIcon className="menu-icon" />
          <Text span color="#fefefe">Cerrar sesión</Text>
        </div>
      </div>
      <Text small>
        Made with
        &nbsp;
        <HeartIcon className="heart" />
        &nbsp;by&nbsp;
        <strong>Juan Vargas</strong>
      </Text>
    </div>
  );
};

export default Footer;
