import axios from 'axios';

/**
 * Create a new instance of axios with a custom config.
 * @link https://github.com/axios/axios#creating-an-instance
 * @param {int} timeout Specifies the number of milliseconds before the request times out.
 * If the request takes longer than `timeout`, the request will be aborted.
 * @returns AxiosInstance
 */
const Constructor = (timeout = 10000) => {
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem('token');
  const headers = {
    'content-type': 'application/json',
    Accept: '*/*',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API, // All requests must go through the proxy
    headers,
    responseType: 'json',
    responseEncoding: 'utf8',
    timeout,
    type: 'json',
  });
};

export default Constructor;
