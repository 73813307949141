import React, { useContext } from 'react';
import { Button, Grid } from '@nextui-org/react';

import CourseContext from '../../../contexts/CouseContext';

import ReactSelect from '../../../components/form/ReactSelect';
import Input from '../../../components/form/Input';
import Textarea from '../../../components/form/Textarea';

import updateForm from '../../../utils/updateForm';
import validateUtil from '../../../utils/validate';

const Form = () => {
  const {
    topic,
    errors,
    trainings,
    instructors,
    evaluationForms,
    setTopic,
    setErrors,
    // setTrainings,
    addedTopics,
    setAddedTopics,
    resetTopic,
  } = useContext(CourseContext);

  const handleChange = (e) => {
    let obj = { ...topic };
    if (e.target.name === 'training') {
      const {
        target: {
          value: { duration },
        },
      } = e;
      const event = { target: { name: 'duration', value: duration } };
      obj = updateForm(event, obj);
    }
    setTopic(updateForm(e, obj));
    setErrors({});
  };

  const validate = () => {
    const validations = [
      {
        name: 'training',
        value: topic.training.label,
        validations: { required: true },
      },
      {
        name: 'instructor',
        value: topic.instructor.label,
        validations: { required: true },
      },
      {
        name: 'evaluationForm',
        value: topic.evaluationForm.label,
        validations: { required: true },
      },
      {
        name: 'duration',
        value: topic.duration,
        validations: { required: true, number: true, greaterThan: 0 },
      },
    ];
    const validateResult = validateUtil(validations);
    if (!validateResult.valid) {
      setErrors(validateResult.errors);
    }
    return validateResult.valid;
  };

  const addTopic = async () => {
    if (!validate()) {
      return;
    }
    setAddedTopics([...addedTopics, topic]);
    // const filterTrainings = trainings.filter(
    //   (x) => x.value !== topic.training.value,
    // );
    // setTrainings(filterTrainings);
    resetTopic();
  };

  const handleCreateTrainings = async (name) => {
    console.log('name: ', name);
  };

  const handleCreateInstructors = async (name) => {
    console.log('name: ', name);
  };

  const handleCreateEvaluationForm = async (name) => {
    console.log('name: ', name);
  };

  return (
    <>
      <Grid xs={12} sm={4}>
        <ReactSelect
          label="Entrenamiento"
          id="training"
          name="training"
          options={trainings}
          onCreateOption={handleCreateTrainings}
          onChange={handleChange}
          value={topic.training}
          createLabel="Crear entrenamiento"
          classNameWrapper="pb-0"
          helperText={errors.training}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <ReactSelect
          label="Instructor"
          id="instructor"
          name="instructor"
          options={instructors}
          onCreateOption={handleCreateInstructors}
          onChange={handleChange}
          value={topic.instructor}
          createLabel="Crear instructor"
          classNameWrapper="pb-0"
          helperText={errors.instructor}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <ReactSelect
          label="Forma de evaluación"
          id="evaluationForm"
          name="evaluationForm"
          options={evaluationForms}
          onCreateOption={handleCreateEvaluationForm}
          onChange={handleChange}
          value={topic.evaluationForm}
          createLabel="Crear evaluación"
          classNameWrapper="pb-0"
          helperText={errors.evaluationForm}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <Input
          label="Duración (en minutos)"
          id="duration"
          name="duration"
          value={topic.duration}
          required
          onChange={handleChange}
          helperText={errors.duration}
          type="number"
          classNameWrapper="pb-0"
        />
      </Grid>
      <Grid xs={12} sm={8}>
        <Textarea
          label="Observaciones"
          id="observation"
          name="observation"
          value={topic.observation}
          required
          onChange={handleChange}
          helperText={errors.observation}
          classNameWrapper="pb-0"
        />
      </Grid>
      <Grid xs={12}>
        <Button type="button" ghost className="w-100" onClick={addTopic}>
          Agregar tema
        </Button>
      </Grid>
    </>
  );
};

export default Form;
