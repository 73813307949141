import React from 'react';
import {
  HomeIcon,
  BookOpenIcon,
  CollectionIcon,
  UsersIcon,
  AcademicCapIcon,
  IdentificationIcon,
  CubeTransparentIcon,
  UserIcon,
  ClockIcon,
  UserGroupIcon,
  DocumentTextIcon,
} from '@heroicons/react/solid';

import MenuItem from './MenuItem';

const Menu = () => (
  <nav className="sidebar-menu">
    <ul className="menu-items">
      <MenuItem href="/" icon={HomeIcon} text="Inicio" slug="dashboard" />
      <MenuItem
        href="/cursos"
        icon={BookOpenIcon}
        text="Cursos"
        slug="courses"
      />
      <MenuItem
        href="/departamentos"
        icon={CollectionIcon}
        text="Departamentos"
        slug="departments"
      />
      <MenuItem
        href="/destinatarios"
        icon={UserGroupIcon}
        text="Destinatarios"
        slug="receivers"
      />
      <MenuItem
        href="/empleados"
        icon={UsersIcon}
        text="Empleados"
        slug="employees"
      />
      <MenuItem
        href="/entrenamientos"
        icon={AcademicCapIcon}
        text="Entrenamientos"
        slug="trainings"
      />
      <MenuItem
        href="/forma-evaluacion"
        icon={DocumentTextIcon}
        text="Formas de evaluación"
        slug="evaluation-forms"
      />
      <MenuItem
        href="/instructores"
        icon={IdentificationIcon}
        text="Instructores"
        slug="instructors"
      />
      <MenuItem
        href="/puestos"
        icon={CubeTransparentIcon}
        text="Puestos"
        slug="jobPositions"
      />
      <MenuItem href="/turnos" icon={ClockIcon} text="Turnos" slug="turns" />
      <MenuItem href="/usuarios" icon={UserIcon} text="Usuarios" slug="users" />
    </ul>
  </nav>
);

export default Menu;
