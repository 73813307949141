import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Text, Checkbox } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Input from '../../components/form/Input';
import InputPassword from '../../components/form/InputPassword';
import Select from '../../components/form/Select';

import GlobalContext from '../../contexts/GlobalContext';
import validateUtil from '../../utils/validate';
import updateForm from '../../utils/updateForm';

import useApiUser from '../../api/useApiUser';
import useApiDepartment from '../../api/useApiDepartment';

const INITIAL_FORM = {
  username: '',
  password: '',
  email: '',
  name: '',
  lastName: '',
  admin: false,
  company: 0,
  department: '',
  blocked: false,
};

const UserForm = () => {
  const { loading, globalState, toast } = useContext(GlobalContext);
  const { id } = useParams();
  const { apiUserCreate, apiUserGet, apiUserUpdate } = useApiUser();
  const { apiDepartmentCombo } = useApiDepartment();
  const navigate = useNavigate();

  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});
  const [addOrEdit, setAddOrEdit] = useState('Agregar');
  const [departments, setDepartments] = useState([]);

  const getData = useCallback(async () => {
    try {
      const resp = await apiUserGet(id);
      setForm({
        username: resp.data.username,
        email: resp.data.email,
        name: resp.data.name,
        lastName: resp.data.lastName,
        admin: resp.data.admin,
        company: resp.data.company.id,
        department: resp.data.department.id,
        blocked: resp.data.blocked,
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  const getComplementaryData = useCallback(async () => {
    try {
      const resp = await apiDepartmentCombo();
      setDepartments(resp.data.data);
    } catch (error) {
      console.log('error: ', error);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (id) {
      setAddOrEdit('Editar');
      getData();
    }
    getComplementaryData();
  }, [id, getData, getComplementaryData]);

  const validate = () => {
    const validations = [
      {
        name: 'username',
        value: form.username,
        validations: { required: true },
      },
      {
        name: 'email',
        value: form.email,
        validations: { required: true, email: true },
      },
      { name: 'name', value: form.name, validations: { required: true } },
      {
        name: 'lastName',
        value: form.lastName,
        validations: { required: true },
      },
      {
        name: 'department',
        value: form.department,
        validations: { required: true },
      },
    ];
    if (!id) {
      validations.push({
        name: 'password',
        value: form.password,
        validations: { required: true },
      });
    }
    const validateResult = validateUtil(validations);
    if (!validateResult.valid) {
      setErrors(validateResult.errors);
    }
    return validateResult.valid;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    try {
      form.company = globalState.user.company.id;
      if (id) {
        delete form.password;
        await apiUserUpdate(id, form);
      } else {
        await apiUserCreate(form);
      }
      navigate('/usuarios');
      toast.fire({
        icon: 'success',
        title: `Usuario ${form.name} ${
          id ? 'actualizado' : 'creado'
        } exitosamente.`,
      });
    } catch (error) {
      console.log('error: ', error);
      setErrors({
        general: `Ocurrio un error al ${
          id ? 'actualizar' : 'crear'
        } el usuario.`,
      });
    }
  };

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setErrors({});
  };

  return (
    <Layout menu="users" title={`${addOrEdit} usuario`} backUrl="/usuarios">
      <Card className="mt-4 form-view">
        <form onSubmit={submit}>
          <Input
            label="Usuario"
            id="username"
            name="username"
            value={form.username}
            required
            onChange={handleChange}
            helperText={errors.username}
          />
          {!id && (
            <InputPassword
              label="Contraseña"
              id="password"
              name="password"
              value={form.password}
              required
              onChange={handleChange}
              helperText={errors.password}
            />
          )}
          <Input
            label="Correo electrónico"
            id="email"
            name="email"
            value={form.email}
            required
            onChange={handleChange}
            helperText={errors.email}
            type="email"
          />
          <Input
            label="Nombre"
            id="name"
            name="name"
            value={form.name}
            required
            onChange={handleChange}
            helperText={errors.name}
          />
          <Input
            label="Apellido"
            id="lastName"
            name="lastName"
            value={form.lastName}
            required
            onChange={handleChange}
            helperText={errors.lastName}
          />
          <Select
            label="Departamentos"
            id="department"
            name="department"
            value={form.department}
            required
            onChange={handleChange}
            helperText={errors.department}
            as="select"
          >
            <option value="">--Selecciona--</option>
            {departments.map((d) => (
              <option value={d.id} key={d.id}>
                {d.attributes.name}
              </option>
            ))}
          </Select>
          {id && (
            <Checkbox
              id="blocked"
              name="blocked"
              checked={!form.blocked}
              className="mb-3"
              onChange={handleChange}
            >
              Activo
            </Checkbox>
          )}
          {globalState.user.admin && (
            <Checkbox
              id="admin"
              name="admin"
              checked={form.admin}
              onChange={handleChange}
              className={`mb-3${id ? ' ml-5' : ''}`}
            >
              Administrador
            </Checkbox>
          )}
          {errors.general && (
            <div className="mb-2">
              <Text color="error" className="mb-4">
                {errors.general}
              </Text>
            </div>
          )}
          <Button type="submit" clickable={!loading}>
            {`${addOrEdit === 'Agregar' ? addOrEdit : 'Actualizar'} usuario`}
          </Button>
        </form>
      </Card>
    </Layout>
  );
};

export default UserForm;
