/* eslint no-undef: 0 */
import { STORAGE_TOKEN, STORAGE_USER } from '../contexts/GlobalReducer';

// TODO: refactor
const callError = (err) => {
  let message = 'Ocurrio un error';
  let status = 400;
  let data = {};

  if (err.response) {
    const { response } = err;
    console.log('ErrorHandler:', response); // eslint-disable-line
    status = response.status;
    if (response.status === 400) {
      message = 'Error en el formulario.';
      data = response.data;
      if (response.data.error && response.data.error.message) {
        message = response.data.error.message;
      }
    } else if (response.status === 401) {
      localStorage.removeItem(STORAGE_USER);
      localStorage.removeItem(STORAGE_TOKEN);
      window.location.href = '/login';
    } else {
      message = response.data.message;
    }
  } else if (err.message && err.message === 'Network Error') {
    message = 'No tiene conexion a internet.';
    status = 408;
  } else if (err.message) {
    message = err.message;
  }
  console.log('ErrorHandler2:', message); // eslint-disable-line
  const error = { message, status, data };
  throw error;
};

export default callError;
