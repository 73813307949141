/* eslint react/destructuring-assignment: 0 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Table from '../../components/table/Table';
import Edit from '../../components/table/buttons/Edit';

import useApiInstructor from '../../api/useApiInstructor';

const InstructorList = () => {
  const { apiInstructorList } = useApiInstructor();

  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    try {
      const resp = await apiInstructorList();
      setData(resp.data.data);
    } catch (error) {
      console.log('error: ', error); // eslint-disable-line
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      { Header: 'Nombre', accessor: 'attributes.name' },
      {
        Header: 'Ubicación',
        accessor: 'attributes.external',
        Cell: (row) => (
          <span>
            {row.row.original.attributes.external ? 'Externo' : 'Interno'}
          </span>
        ),
      },
      {
        Header: 'Editar',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        headerClassName: 'text-center flex-center',
        Cell: (row) => (
          <Edit href={`/instructores/editar/${row.row.original.id}`} /> // eslint-disable-line
        ),
      },
    ],
    [],
  );

  return (
    <Layout menu="instructors" title="Instructores">
      <Card className="mt-4">
        <Table columns={columns} data={data} addPath="/instructores/agregar" />
      </Card>
    </Layout>
  );
};

export default InstructorList;
