import React, { useContext } from 'react';
import { Text } from '@nextui-org/react';
import { XIcon } from '@heroicons/react/outline';

import GlobalContext from '../../../contexts/GlobalContext';

import logo from '../../../assets/img/logoWhite.png';

const Header = () => {
  const { toggleMenu, globalState: { user } } = useContext(GlobalContext);

  return (
    <div className="sidebar-header">
      <div className="sidebar-wrapper-close-icon" onClick={toggleMenu} role="button" tabIndex={0}>
        <XIcon className="sidebar-close-icon" />
      </div>
      <img src={logo} alt="Tracos" className="logo" />
      <Text h4 color="white">{`${user.name} ${user.lastName}`}</Text>
      <Text small color="white">{user.department.name}</Text>
    </div>
  );
};

export default Header;
