/* eslint no-undef: 0 */
const types = {
  updateUser: 'updateUser',
  signUp: 'signUp',
  logout: 'logout',
  setLoading: 'setLoading',
  setToken: 'setToken',
};

const STORAGE_USER = 'user';
const STORAGE_TOKEN = 'token';

/**
 * Reducer to control the authentication flow throughout the application
 * @param {*} state Actual state
 * @param {*} action
 * @returns New state
 */
const GlobalReducer = (state, action) => {
  let user = {};
  switch (action.type) {
    case types.updateUser:
      user = {
        ...state.user,
        [action.name]: action.value,
      };
      return {
        ...state,
        user,
      };
    case types.signUp:
      localStorage.setItem(STORAGE_USER, JSON.stringify(action.userInfo));
      return {
        ...state,
        isAuthenticated: true,
        user: action.userInfo,
      };
    case types.logout:
      localStorage.removeItem(STORAGE_USER);
      localStorage.removeItem(STORAGE_TOKEN);
      return {
        user: action.user,
        isAuthenticated: false,
        token: '',
      };
    case types.setLoading:
      return {
        ...state,
        loading: action.value,
      };
    case types.setToken:
      localStorage.setItem(STORAGE_TOKEN, action.token);
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
};

export { types, STORAGE_USER, STORAGE_TOKEN };
export default GlobalReducer;
