import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Table from '../../components/table/Table';
import Edit from '../../components/table/buttons/Edit';

import useApiCourse from '../../api/useApiCourse';

const CourseList = () => {
  const { apiCourseList } = useApiCourse();

  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    try {
      const resp = await apiCourseList();
      const d = resp.data.map((x) => ({
        ...x,
        folio: `${x.course.id}-${x.id}`,
      }));
      setData(d);
    } catch (error) {
      console.log('error: ', error); // eslint-disable-line
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      { Header: 'Folio', accessor: 'folio' },
      { Header: 'Código', accessor: 'training.code' },
      { Header: 'Nombre', accessor: 'training.name' },
      { Header: 'Motivo', accessor: 'course.reason' },
      { Header: 'Fecha', accessor: 'course.date' },
      { Header: 'Instructor', accessor: 'instructor.name' },
      { Header: 'Forma de evaluación', accessor: 'evaluation_form.name' },
      { Header: 'Duración', accessor: 'duration' },
      { Header: 'Observaciones', accessor: 'observation' },
      {
        Header: 'Editar',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        headerClassName: 'text-center flex-center',
        Cell: (row) => (
          <Edit href={`/cursos/editar/${row.row.original.course.id}`} /> // eslint-disable-line
        ),
      },
    ],
    [],
  );

  return (
    <Layout menu="courses" title="Cursos">
      <Card className="mt-4">
        <Table columns={columns} data={data} addPath="/cursos/agregar" />
      </Card>
    </Layout>
  );
};

export default CourseList;
