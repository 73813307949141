import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../form/Input';

// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Buscar en ${count} registros...`}
      size="xs"
      aria-label="Search"
      classNameWrapper="p-0"
    />
  );
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    preFilteredRows: PropTypes.array,
    setFilter: PropTypes.func,
  }).isRequired,
};

export default DefaultColumnFilter;
