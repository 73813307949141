import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Helvetica',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 12,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  image: {
    width: '120px',
  },
  titleContainer: {
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 21,
    textAlign: 'right',
  },
  date: {
    fontSize: 12,
    textAlign: 'right',
    color: 'grey',
  },
  infoContainer: {
    flexDirection: 'row',
  },
  label: {
    fontFamily: 'Helvetica',
    fontSize: 14,
    width: 75,
  },
  value: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 12,
    borderWidth: 1,
    borderColor: '#bff0fd',
    fontSize: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'left',
    flexGrow: 1,
  },
  headerCell: {
    borderRightColor: '#90e5fc',
    borderRightWidth: 1,
    fontFamily: 'Helvetica-Bold',
    padding: 4,
  },
  cell: {
    borderRightColor: '#90e5fc',
    borderRightWidth: 1,
    padding: 4,
    height: '100%',
    justifyContent: 'center',
  },
  colDate: {
    width: '14%',
  },
  colCode: {
    width: '10%',
  },
  colName: {
    width: '27%',
  },
  colDesc: {
    width: '22%',
  },
  colInstructor: {
    width: '15%',
  },
  colDuration: {
    width: '12%',
    textAlign: 'right',
  },
  colTotal: {
    width: '88%',
    textAlign: 'right',
  },
  tableFooter: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  },
  row: {
    borderTopColor: '#bff0fd',
    borderTopWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

export default styles;
