import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/outline';

const Edit = ({ href }) => (
  <div className="w-100 text-center">
    <Link to={href} className="p-2">
      <PencilIcon className="text-primary w-1" />
    </Link>
  </div>
);

Edit.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Edit;
