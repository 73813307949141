import { useContext } from 'react';

import Constructor from './constructor';
import errorHandler from './errorsHandler';
import GlobalContext from '../contexts/GlobalContext';

const useApiInstructor = () => {
  const { setLoading } = useContext(GlobalContext);
  const API = Constructor(); // Create instance

  const apiInstructorList = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/instructors?sort[0]=name:asc&filters[active][$eq]=true',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiInstructorCombo = async () => {
    setLoading(true);
    try {
      return await API.get(
        '/instructors?sort[0]=name:asc&filters[active][$eq]=true&fields[0]=name&fields[1]=external',
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiInstructorGet = async (id) => {
    setLoading(true);
    try {
      return await API.get(`/instructors/${id}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiInstructorCreate = async (data) => {
    setLoading(true);
    try {
      return await API.post('/instructors', { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const apiInstructorUpdate = async (id, data) => {
    setLoading(true);
    try {
      return await API.put(`/instructors/${id}`, { data });
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    apiInstructorList,
    apiInstructorCombo,
    apiInstructorGet,
    apiInstructorCreate,
    apiInstructorUpdate,
  };
};

export default useApiInstructor;
