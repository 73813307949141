import React, { useContext } from 'react';
import { Grid } from '@nextui-org/react';

import CourseContext from '../../contexts/CouseContext';

import Subtitle from '../../components/Subtitle';
import Input from '../../components/form/Input';
import ReactSelect from '../../components/form/ReactSelect';

import updateForm from '../../utils/updateForm';
import useApiReceiver from '../../api/useApiReceiver';

const GeneralData = () => {
  const {
    form,
    errors,
    receivers,
    companyId,
    setForm,
    setErrors,
    setReceivers,
  } = useContext(CourseContext);
  const { apiReceiverCreate } = useApiReceiver();

  const handleChange = (e) => {
    setForm(updateForm(e, form));
    setErrors({});
  };

  const handleCreateReceivers = async (name) => {
    const data = {
      name,
      active: true,
      company: companyId,
    };
    try {
      const resp = await apiReceiverCreate(data);
      const {
        data: {
          data: { id: idReceiver },
        },
      } = resp;
      const newOption = { value: idReceiver.toString(), label: name };
      setReceivers([...receivers, newOption]);
      setForm({ ...form, receiver: newOption });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <Subtitle>Datos generales</Subtitle>
      <Grid.Container gap={2} justify="center">
        <Grid xs={12}>
          <Input
            label="Motivo"
            id="reason"
            name="reason"
            value={form.reason}
            required
            onChange={handleChange}
            helperText={errors.reason}
            classNameWrapper="pb-0"
          />
        </Grid>
        <Grid xs={12} sm={5}>
          <Input
            label="Fecha"
            id="date"
            name="date"
            value={form.date}
            required
            onChange={handleChange}
            helperText={errors.date}
            type="date"
            classNameWrapper="pb-0"
          />
        </Grid>
        <Grid xs={12} sm={5}>
          <ReactSelect
            label="Destinado a"
            id="receiver"
            name="receiver"
            options={receivers}
            onCreateOption={handleCreateReceivers}
            onChange={handleChange}
            value={form.receiver}
            createLabel="Crear destinatario"
            helperText={errors.receiver}
          />
        </Grid>
        <Grid xs={12} sm={2}>
          <Input
            label="Duración total"
            id="totalDuration"
            name="totalDuration"
            value={form.totalDuration}
            required
            onChange={handleChange}
            helperText={errors.totalDuration}
            type="number"
            classNameWrapper="pb-0"
            disabled
          />
        </Grid>
      </Grid.Container>
    </>
  );
};

export default GeneralData;
