import React from 'react';
import { Input as InputNextUI } from '@nextui-org/react';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from '@heroicons/react/outline';

const Select = ({ classNameWrapper, helperText, children, ...props }) => (
  <div
    className={`form-control${classNameWrapper ? ` ${classNameWrapper}` : ''}`}
  >
    <InputNextUI
      bordered
      fullWidth
      color={helperText ? 'error' : 'primary'}
      helperText={helperText}
      helperColor="error"
      as="select"
      contentRight={<ChevronDownIcon width="1rem" />}
      {...props}
    >
      {children}
    </InputNextUI>
  </div>
);

Select.propTypes = {
  classNameWrapper: PropTypes.string,
  helperText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Select.defaultProps = {
  classNameWrapper: '',
  helperText: '',
};

export default Select;
