import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Table from '../../components/table/Table';
import Edit from '../../components/table/buttons/Edit';

import useApiDepartment from '../../api/useApiDepartment';

const DepartmentList = () => {
  const { apiDepartmentList } = useApiDepartment();

  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    try {
      const resp = await apiDepartmentList();
      setData(resp.data.data);
    } catch (error) {
      console.log('error: ', error); // eslint-disable-line
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      { Header: 'Código', accessor: 'attributes.code' },
      { Header: 'Nombre', accessor: 'attributes.name' },
      { Header: 'Descripción', accessor: 'attributes.description' },
      {
        Header: 'Editar',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        headerClassName: 'text-center flex-center',
        Cell: (row) => (
          <Edit href={`/departamentos/editar/${row.row.original.id}`} /> // eslint-disable-line
        ),
      },
    ],
    [],
  );

  return (
    <Layout menu="departments" title="Departamentos">
      <Card className="mt-4">
        <Table columns={columns} data={data} addPath="/departamentos/agregar" />
      </Card>
    </Layout>
  );
};

export default DepartmentList;
