import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@nextui-org/react';

import Layout from '../../components/layout/Layout';
import Table from '../../components/table/Table';
import Edit from '../../components/table/buttons/Edit';

import useApiUser from '../../api/useApiUser';

const UserList = () => {
  const { apiUserList } = useApiUser();

  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    try {
      const resp = await apiUserList();
      setData(resp.data);
    } catch (error) {
      console.log('error: ', error); // eslint-disable-line
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = useMemo(
    () => [
      { Header: 'Usuario', accessor: 'username' },
      { Header: 'Nombre', accessor: 'name' },
      { Header: 'Apellido', accessor: 'lastName' },
      { Header: 'E-mail', accessor: 'email' },
      {
        Header: 'Editar',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        headerClassName: 'text-center flex-center',
        Cell: (row) => (
          <Edit href={`/usuarios/editar/${row.row.original.id}`} /> // eslint-disable-line
        ),
      },
    ],
    [],
  );

  return (
    <Layout menu="users" title="Usuarios">
      <Card className="mt-4">
        <Table columns={columns} data={data} addPath="/usuarios/agregar" />
      </Card>
    </Layout>
  );
};

export default UserList;
