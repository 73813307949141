import React from 'react';
import { NextUIProvider } from '@nextui-org/react';

import { GlobalProvider } from './contexts/GlobalContext';
import AppRouter from './routers/AppRouter';

import './assets/css/styles.scss';

const App = () => (
  <NextUIProvider>
    <GlobalProvider>
      <AppRouter />
    </GlobalProvider>
  </NextUIProvider>
);

export default App;
